<template>
<div>

    <!-- without login -->
    <div class="joinMailSecBg">
        <div class="joinMailContentBg">
            <p class="joinMailHeadTxt mb-0">Join Our Newsletter</p>
            <p class="joinMailDesctxt">Be the first to know about exclusive discounts, new launches and more!</p>
            <div v-if="showInputField==false">
                <div class="position-relative email-input-container">
                    <i class="fa-light fa-envelope mailIcon"></i>
                    <input type="email" class="form-control joinEmailInputField" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email address" v-model="emailAddress" @focus="removeDefault()" @input="validate_inputs('emailAddress')">
                    <button class="subscribe-btn" @click="join_mail()">Subscribe</button>
                </div>
                <hr class="joinMailHr">
            </div>
            <div id="phoneError" class="text-left mb-1 errorMsgStyles text-center" v-if="error.emailAddress" :style="{ color: 'red' }">
                {{ error.emailAddress }}
            </div>

            <div v-if="mailSubscribeData?.message">
                <div :class="this.mailSubscribeData.message =='successfully subscribed'? 'joinMailSuccessBg' : 'joinMailSuccessBgExist'">
                    <i class="fa-light fa-circle-check joinMailCheckIcon" v-if="this.mailSubscribeData.message =='successfully subscribed'"></i>
                    <p class="joinMailConfirmTxt" v-if="this.mailSubscribeData.message =='successfully subscribed'">Thanks for subscribing to our mailing list, keep an eye out for exclusive discounts, new watch competitions and more </p>
                    <p class="joinMailConfirmTxt" v-else>{{this.mailSubscribeData.message}} </p>
                </div>
                

            </div>
        </div>
    </div>

    <!-- with login -->
    <!-- <div v-else class="joinMailSecBg">
        <div class="joinMailContentBg">
            <p class="joinMailHeadTxt mb-0">Join Our Newsletter</p>
            <p class="joinMailDesctxt">Be the first to know about exclusive discounts, new launches and more!</p>
            <div v-if="checkMailData?.subscribed == false">
                <div v-if="mailSubscribeData.status!=200">
                    <div class="position-relative email-input-container">
                        <i class="fa-light fa-envelope mailIcon"></i>
                        <input type="email" class="form-control joinEmailInputField" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email address" v-model="emailAddress" @input="validate_inputs('emailAddress')">
                        <button class="subscribe-btn" @click="join_mail()">Subscribe</button>
                    </div>
                    <hr class="joinMailHr">
                </div>
                <div v-else>
                <div class="joinMailSuccessBg">
                    <i class="fa-light fa-circle-check joinMailCheckIcon"></i>
                    <p class="joinMailConfirmTxt">Thanks for subscribing to our mailing list, keep an eye out for exclusive discounts, new watch competitions and more </p>
                </div>
            </div>
                <div id="phoneError" class="text-left mb-1 errorMsgStyles text-center" v-if="error.emailAddress" :style="{ color: 'red' }">
                    {{ error.emailAddress }}
                </div>
            </div>
            <div v-else>
                <div class="joinMailSuccessBg">
                    <i class="fa-light fa-circle-check joinMailCheckIcon"></i>
                    <p class="joinMailConfirmTxt">Thanks for subscribing to our mailing list, keep an eye out for exclusive discounts, new watch competitions and more </p>
                </div>
            </div>

        </div>
    </div> -->

</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import {
    jwtDecode
} from "jwt-decode";
import vaildationMixin from "../../mixins/validation.js"
export default {
    data() {
        return {
            mailSubscribeData: "",
            checkMailData: "",
            emailAddress: '',
            error: {
                emailAddress: ''
            },
            userSession: "",
            showInputField: false
        };
    },
    components: {},
    mixins: [vaildationMixin],
    computed: {
        ...mapGetters(['getJoinMailData', 'getCheckMailData']),
        isFormFilled() {
            return (
                this.emailAddress != "" &&
                this.error.emailAddress == ""
            );
        },
    },
    created() {
        this.userSession = localStorage.getItem("user_session");
        if (this.userSession) {
            this.check_mail_subscription()
        }

    },
    methods: {
        join_mail() {
            if (this.isFormFilled) {
                this.mailSubscribe();
                if (this.userSession) {
                    this.check_mail_subscription()
                }
            }
        },
        async mailSubscribe() {
            try {
                await this.$store.dispatch("join_mail", {
                    email: this.emailAddress.trim()
                });
                this.mailSubscribeData = this.$store.getters.getJoinMailData;
                if (this.mailSubscribeData.status == 200) {
                    if (this.mailSubscribeData.message == "successfully subscribed") this.showInputField = true
                    this.emailAddress = ''
                }
            } catch (error) {

            }
        },
        removeDefault() {
            this.mailSubscribeData = "";
        },
        async check_mail_subscription() {
            const decoded = jwtDecode(this.userSession);
            const userEmail = decoded.request.user_email;

            try {
                await this.$store.dispatch("check_email", {
                    email: userEmail.trim()
                });
                this.checkMailData = this.$store.getters.getCheckMailData;
                if (this.checkMailData == 200) {
                    this.emailAddress = ''

                }
            } catch (error) {

            }
        },

        validate_inputs(field) {

            if (field === 'emailAddress') {
                let emailAddress = this.validate_email(this.emailAddress);
                if (!emailAddress.valid) {
                    this.error.emailAddress = emailAddress.error;
                } else {
                    this.error.emailAddress = '';
                }
            }
        },
    },
};
</script>
