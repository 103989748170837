<template>
<div>
    <div class="mwcEntrepreneurBg">
        <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcEntrepreneurImg.svg" alt="" class="mwcEntrepreneurImgs mb-withLoginEntreprenuerImg">
        <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcGQImg.svg" alt="" class="mwcEntrepreneurImgs mb-withLoginGqImg">
        <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcEsquireImg.svg" alt="" class="mwcEntrepreneurImgs mb-withLoginesquireImg">
        <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcForbesImg.svg" alt="" class="mwcEntrepreneurImgs mb-withLoginForbesImg">
    </div>
    <!-- without login  -->
    <div class="mwcPaymentSecBg">
        <h6 class="mwcPaymentTxt">PAYMENTS WE ACCEPT</h6>
        <div class="paymentsSecDisplay">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcVisaImg.svg" alt="" class="paymentImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcmasterCardImg.svg" alt="" class="paymentImgs">
            <div class="mwcpaypalImgBg paypalPaymentMethods d-flex justify-content-center align-items-center">
                <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcPaypalImg.svg" alt="" class="">
            </div>
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcGpayImg.svg" alt="" class="paymentImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcApplepayImg.svg" alt="" class="paymentImgs mr-0">
        </div>
    </div>

    <!-- with login -->



</div>
</template>

<script>
export default {

    data: () => ({
        userSession: "",
    }),

    created() {
        this.userSession = localStorage.getItem("user_session");
    },
}
</script>
