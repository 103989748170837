<template>
<div class="charitySecBgWidth">
    <div class="position-relative mwcCharitySecWeb">

        <div class=" mwcCharityPartnerSecBg webMwcCharityImg" :style="{ 'background-image': `url(${getBackgroundImage()})` }">
            <div class="row m-0">
                <div class="col-5 mob-charitySecWidth p-0">
                    <div class="d-flex align-items-center mwcCharityImgs">
                        <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/sevenCleanSeasImg.svg" alt="" class="mwcOceanImg">
                        <hr class="mwcCharityHr m-0">
                        <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/monacoWatchClubImg.svg" alt="" class="monacoWatchClubImg">
                    </div>
                    <h6 class="mwcSupportOceantxt">We proudly support {{ charityData.name }}</h6>
                    <p class="mwcSupprtCharityDesc" v-html="charityData.description"></p>
                    <p class="mwcCoralTxtBg"><em class="fa-light fa-bottle-water mwcSpaIcon"></em> Together, we’ve helped clear <span class="mwcOceanTxtBg"> {{ Math.ceil(charityData.totalPlastic) }}kg</span> of ocean plastic!</p>
                    <div class="mb-charityBtn">
                        <button type="button" class="btn charityLearnMoreBtn" @click="charity_page()">LEARN MORE</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import DOMPurify from 'dompurify';

import {
    urlLink,
    socialImgUrlLink
} from '@/mixins/s3bucket.js'
import {
    mapGetters
} from 'vuex';

export default {
    data() {
        return {
            charityData: [],
            urlLink: urlLink,
            socialImgUrlLink: socialImgUrlLink
        }
    },
    computed: {
        ...mapGetters(['getcharityData'])
    },

    created() {
        this.fetch_charity()
        this.getBackgroundImage()
    },
    methods: {
        getBackgroundImage() {
            const isMobile = window.matchMedia("(max-width: 767px)").matches;
            return isMobile ?
                this.urlLink + this.charityData.mini_img_url :
                this.urlLink + this.charityData.main_img_url;
        },
        async fetch_charity() {
            try {
                await this.$store.dispatch('fetchCharity');
                this.charityData = this.getcharityData[0];
            } catch (error) {
                console.error("Error fetching all raffles:", error);
            }
        },

        charity_page() {
            this.$router.push({
                name: 'charityWithoutLogin',
            });
        }
    }
}
</script>
