<template>
<div>

    <!-- <div class="mwcNewsPageBg">
        <div class="row newsAndBlogsSecGap">
            <div class="col-4 newsAndBlogsBg p-0" v-for="news in newsData" :key="news">
                <div class="cursor-pointer" @click="news_article(news.sort_id)">
                    <img :src="urlLink + news.img_url" alt="" class="newsAndBlogsImg">
                    <h3 class="collectorsEditionTxt">Collectors Edition: {{ news.title }}</h3>
                    <p class="newsDateTxt mb-0">{{ formatDateMonth(news.created_at) }} | {{Math.min(Math.max(Math.ceil(news.content.length / 3000), 1),5)}} minute read</p>

                    <p class="newsDescription mb-0">{{ news.card_description }}</p>
                </div>
            </div>
        </div>
    </div> -->

    <div class="mwcNewsSectionBg">
        <div class="row newsAndBlogsSecGapNew">
            <div class="col-6 newsAndBlogsNewBg p-0" v-for="news in newsData" :key="news">
                <div class="cursor-pointer" @click="news_article(news.sort_id)">
                    <!-- <img :src="urlLink + news.img_url" alt="" class="newsImageSec"> -->
                    <div class="newsImageSec"  :style="{  background: `linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%), url('${urlLink + news.img_url}')`,backgroundSize: 'cover', backgroundPosition: 'center' }">
                        <div class="newsDescrSection">
                            <p class="newsTimings">
                                🕑 {{Math.min(Math.max(Math.ceil(news.content.length / 3000), 1),5)}} min read
                            </p>
                            <p class="newsDescriptionNew newDescTxtEllipsis">
                                {{ news.title }}
                            </p>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="readButton mwcButtonStyles" @click="news_article(news.sort_id)">
                                Read Article
                            </button>
                            </div>
                            
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import moment from 'moment-timezone';
import timer from '@/mixins/timer';

import {
    mapGetters
} from 'vuex';
import {
    urlLink,
    socialImgUrlLink
} from '@/mixins/s3bucket.js'
import '@/assets/css/newsAndBlogs.css'

import scrollTop from '@/mixins/scrollTop.js'
export default {
    components: {

    },
    data: () => ({
        userSession: "",
        lastDate: "",
        urlLink: urlLink,
        socialImgUrlLink: socialImgUrlLink,
        // loadmoreHide: 0,
        newsData: []
    }),
    mixins: [scrollTop, timer],
    computed: {
        ...mapGetters(['getNewsReadData'])
    },
    created() {

        this.scrollTop()

        this.userSession = localStorage.getItem("user_session");
        this.fetch_news()
    },
    methods: {

        async fetch_news() {
            try {
                await this.$store.dispatch('fetchNewsData', this.lastDate);
                this.newsReadData = this.getNewsReadData.news.slice(0, 2);                
                // this.loadmoreHide = this.newsReadData.length
                this.newsData = this.newsData.concat(this.newsReadData);                

            } catch (error) {
                console.error("Error fetching news:", error);
            }
        },
        formatDateMonth(dateString) {
            return moment(dateString.split('.000Z')[0])
                .format('MMMM Do')
        },
        // async loadMore() {
        //     try {
        //         const lastNews = this.newsData[this.newsData.length - 1];
        //         this.lastDate = lastNews.created_at_ms;
        //         await this.fetch_news();
        //     } catch (error) {
        //         console.error("Error loading more news:", error);
        //     }
        // },

        news_article(sort_id) {
            this.$router.push({
                name: "newsArticle",
                params: {
                    sort_id
                }

            });
        },

    }

}
</script>
