<template>
<div class="col-4 p-0">
    <div class="d-flex align-items-center justify-content-end">
        <h2 class="mwcLoginTxt mb-0" @click="login_now()">Log in</h2>
        <button type="button" class="btn mwcSignUpTxt mwcButtonStyles" data-bs-toggle="modal" data-bs-target="#signUpModal" @click="clear_fields()">Sign up</button>
        <em class="fa-light fa-bag-shopping mwcNavShopBagIcon" @click="go_to_cart()"></em>
    </div>
</div>

<!--sign up Modal -->
<div class="modalBackdropCustom modal fade" id="signUpModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl newSignupModalContentBg">
        <div class="modal-content newSignupModalBg">
            <div class="modal-body p-0 newSignUpModalBodyBg">
                <div class="row align-items-center">
                    <div class="col-2">
                    </div>
                    <div class="col-8">
                        <h1 class="signUpTxt mb-0">Sign up</h1>
                    </div>
                    <div class="col-2 text-end">
                        <em class="fa-regular fa-xmark newSignUpCloseMark" data-bs-dismiss="modal" @click="clear_fields()"></em>
                    </div>
                </div>
                <p class="newSignUpTxt">Already have an account? <span class="signUpNowTxt" @click="login_now()"> Log in.</span></p>
                <div class="signUpFieldsSecBg">
                    <div class="input-container" :class="{active: isfullNameActive,'has-error': error.fullName, focused: isfullNameFocused}">
                        <label for="signUpFirstName" class="input-label" :class="{ 'has-error': error.fullName }">Full Name*</label>
                        <div class="row m-0 align-items-center">
                            <div class="col-10 p-0">
                                <input type="text"  class="input-field form-control signUpNameFields" id="signUpFirstName" v-model="fullName" @input="validate_inputs('fullName')" @focus="handleFocus('fullName')" @blur="handleBlur('fullName')" :class="{ 'has-error': error.fullName }">
                            </div>
                            <div class="col-2 pl-0 text-end">
                                <i v-if="error.fullName" class="fa-light fa-triangle-exclamation error-check"></i>
                                <i v-else-if="correctInput.fullName" class="fa-light fa-check error-check"></i>
                            </div>
                        </div>
                        <div id="fullNameError" class="text-left newErrorMsgTxtFull mr-1" v-if="error.fullName">
                            {{ error.fullName }}
                        </div>
                    </div>

                    <div class="signUpPhone">
                        <div class="input-container" :class="{active: isEmailActive,'has-error': error.emailAddress, focused: isEmailFocused}">
                            <label for="signUpemail" class="input-label" :class="{ 'has-error': error.emailAddress }">Email Address*</label>
                            <div class="row m-0 align-items-center">
                                <div class="col-10 p-0">
                                    <input type="text" class="input-field form-control signUpInputFields " id="signUpemail" placeholder=" " v-model="emailAddress" @focus="handleFocus('email')" @blur="handleBlur('email')" @input="validate_inputs('emailAddress')" :class="{ 'has-error': error.emailAddress }">
                                </div>
                                <div class="col-2 pl-0 text-end">
                                    <i v-if="error.emailAddress" class="fa-light fa-triangle-exclamation error-warning"></i>
                                    <i v-else-if="correctInput.email" class="fa-light fa-check error-warning"></i>
                                </div>
                            </div>

                            <div id="emailAddressError" class="text-left newErrorMsgTxt" v-if="error.emailAddress">
                                {{ error.emailAddress }}
                            </div>
                        </div>

                        <div class="input-container" :class="{ active: isDateOfBirthActive,'has-error': error.dateOfBirth, focused: isDateOfBirthFocused }">
                            <label for="dateOfBirth" class="input-label" :class="{ 'has-error': error.dateOfBirth }">
                                Date of Birth (DD/MM/YYYY)
                            </label>
                            <div class="row m-0 align-items-center">
                                <div class="col-10 p-0">
                                    <input type="text" class="input-field form-control signUpInputFields" id="dateOfBirth" v-model="dateOfBirth" @input="formatDateOfBirth" @focus="handleFocus('dateOfBirth')" @blur="handleBlur('dateOfBirth')" :class="{ 'has-error': error.dateOfBirth }" />
                                </div>
                                <div class="col-2 pl-0 text-end">
                                    <i v-if="error.dateOfBirth" class="fa-light fa-triangle-exclamation error-warning"></i>
                                    <i v-else-if="correctInput.dateOfBirth" class="fa-light fa-check error-warning"></i>
                                </div>
                            </div>

                            <div id="dateOfBirthError" class="text-left newErrorMsgTxt" v-if="error.dateOfBirth">
                                {{ error.dateOfBirth }}
                            </div>
                        </div>
                    </div>

                    <div class="signUpPhone">
                        <div class="input-container" :class="{ active: isPasswordActive, 'has-error': error.password, focused: isPasswordFocused }">
                            <label for="signUpPswd" class="input-label" :class="{ 'has-error': error.password }">Password*</label>
                            <input :type="isPasswordVisible ? 'text' : 'password'" class="input-field form-control signUpInputFields" id="signUpPswd" v-model="password" @input="validate_inputs('password')" @focus="handleFocus('password')" @blur="handleBlur('password')" :class="{ 'has-error': error.password }">
                            <img v-if="isPasswordVisible" src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/eye-sharp-light.svg" class="cursor-pointer eyeCloseIcon" @click="togglePasswordVisibility" />
                            <img v-else src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/eye-slash-sharp-light.svg" class="cursor-pointer eyeCloseIcon" @click="togglePasswordVisibility" />
                            <div id="passwordError" class="text-left newErrorMsgTxt" v-if="error.password">
                                {{ error.password }}
                            </div>
                        </div>

                        <div class="input-container" :class="{ active: isconfirmPasswordActive, 'has-error': error.confirmPassword,focused: isconfirmPasswordFocused }">
                            <label for="confirmPassword" class="input-label" :class="{ 'has-error': error.confirmPassword }">Confirm Password*</label>
                            <input :type="isConfPasswordVisible ? 'text' : 'password'" id="confirmPassword" class="input-field form-control signUpInputFields" autocomplete="new-password" v-model="confirmPassword" @input="validate_inputs('confirmPassword')" @focus="handleFocus('confirmPassword')" @blur="handleBlur('confirmPassword')" :class="{ 'has-error': error.confirmPassword }">
                            <img v-if="isConfPasswordVisible" src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/eye-sharp-light.svg" class="cursor-pointer eyeCloseIcon" @click="toggleConfPasswordVisibility" />
                            <img v-else src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/eye-slash-sharp-light.svg" class="cursor-pointer eyeCloseIcon" @click="toggleConfPasswordVisibility" />
                            <div id="passwordError" class="text-left newErrorMsgTxt" v-if="error.confirmPassword">
                                {{ error.confirmPassword }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="newPasswordFieldSecBg">
                        <div class="row">
                            <div class="col-2">
                                <i class="fa-light fa-xmark pswdCloseMark"></i>
                            </div>
                            <div class="col-10">
                                <p class="eightCharMinTxt mb-0">8 characters minimum</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <i class="fa-light fa-xmark pswdCloseMark"></i>
                            </div>
                            <div class="col-10">
                                <p class="eightCharMinTxt mb-0">1 lowercase letter</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <i class="fa-light fa-xmark pswdCloseMark"></i>
                            </div>
                            <div class="col-10">
                                <p class="eightCharMinTxt mb-0">1 capital letter</p>
                            </div>
                        </div>
                        <div class="row flex-nowrap">
                            <div class="col-1">
                                <i class="fa-light fa-xmark pswdCloseMark"></i>
                            </div>
                            <div class="col-11">
                                <p class="eightCharMinTxt specialCharTxt mb-0">1 number or special character e.g. 123!@#
                                </p>
                            </div>
                        </div>

                    </div> -->
                    <div class="newReferralSecBg">
                        <p class="newGotReferralCodeTxt mb-0">Got a referral code?</p>
                        <p class="newRefCodeApply mb-0">Apply</p>
                    </div>
                    <div class="d-flex">
                        <input type="checkbox" class="signUpCheckBox" v-model="checkOtp" id="exampleCheck3">
                        <label class="form-check-label signUpCheckBoxTxt text-left" for="exampleCheck3">Subscribe to our mailing list and be the first to hear about exclusive offers, discounts and early access raffles</label>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn newCreateAcntTxt disable" v-if="!isFormFilled"> create an account </button>
                        <button type="button" class="btn newCreateAcntTxt" v-else @click="sign_up()" :disabled="isLoading"> create an account </button>
                    </div>
                    <div id="apiError" class="text-center mt-4 newErrorMsgTxt" v-if="error.apiError">
                        {{ error.apiError }}
                    </div>
                    <p class="signUpCheckTxt">By creating an account, you agree to our <a href="/termsAndConditions" class="mwcNewSignUpTxtColor"> Terms and Conditions</a>, our <a href="/privacyPolicy" class="mwcNewSignUpTxtColor"> Privacy Policy</a> and that you are 18 years or above</p>
                    <div class="newSignUpHrLine">
                        <p class="orSignUpTxt">Or Sign up with</p>
                    </div>
                    <div class="d-flex newSignUpSocialImgs">
                        <button type="button" class="btn loginWithGoogleBg" @click="facebook_signUp()"> <em class="fa-brands fa-facebook-f fbIcon"></em></button>
                        <button type="button" class="btn loginWithGoogleBg mb-2" @click="google_signUp()"> <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/googleImg.svg" alt="" class="newGoogleSignUpImg"></button>
                        <button type="button" class="btn loginWithGoogleBg" @click="apple_signUp()"> <em class="fa-brands fa-apple signUpAppleIcon"></em></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- sign in modal -->
<div class="modalBackdropCustom modal fade" id="signInModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl newSigninModalContentBg">
        <div class="modal-content newSigninModalBg">
            <div class="modal-body p-0 newSignInModalBodyBg">
                <div class="row align-items-center">
                    <div class="col-2">
                    </div>
                    <div class="col-8">
                        <h1 class="mb-0 signInTxt">Login</h1>
                    </div>
                    <div class="col-2 text-end">
                        <em class="fa-regular fa-xmark newSignUpCloseMark newSignInCloseMark" data-bs-dismiss="modal" @click="clear_fields()"></em>
                    </div>
                </div>
                <div class="signinFieldsSecBg">
                    <div class="signUpPhone signInPhone">
                        <div class="input-container" :class="{active: isEmailActive,'has-error': error.emailAddress, focused: isEmailFocused}">
                            <label for="signInemail" class="input-label" :class="{ 'has-error': error.emailAddress }">Email Address*</label>
                            <div class="row m-0 align-items-center">
                                <div class="col-10 p-0">
                                    <input type="text" class="input-field form-control  signinInputFields " id="signInemail" v-model="emailAddress" @focus="handleFocus('email')" @blur="handleBlur('email')" @input="validate_inputs('emailAddress')" :class="{ 'has-error': error.emailAddress }">
                                </div>
                                <div class="col-2 pl-0 text-end">
                                    <i v-if="error.emailAddress" class="fa-light fa-triangle-exclamation error-warning signinError-warning"></i>
                                    <i v-else-if="correctInput.email" class="fa-light fa-check error-warning signinError-warning"></i>
                                </div>
                            </div>
                            <div id="emailAddressError" class="text-left newErrorMsgTxt signInErrorText" v-if="error.emailAddress">
                                {{ error.emailAddress }}
                            </div>
                        </div>
                    </div>

                    <div class="signUpPhone signInPhone">
                        <div class="input-container" :class="{ active: isPasswordActive, 'has-error': error.password, focused: isPasswordFocused }">
                            <label for="signInPswd" class="input-label" :class="{ 'has-error': error.password }">Password*</label>
                            <input :type="isPasswordVisible ? 'text' : 'password'" class="input-field form-control signinInputFields" autocomplete="new-password" id="signInPswd" v-model="password" @input="validate_inputs('password')" @focus="handleFocus('password')" @blur="handleBlur('password')" :class="{ 'has-error': error.password }" />
                            <img v-if="isPasswordVisible" src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/eye-sharp-light.svg" class="cursor-pointer eyeCloseIcon" @click="togglePasswordVisibility" />
                            <img v-else src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/eye-slash-sharp-light.svg" class="cursor-pointer eyeCloseIcon" @click="togglePasswordVisibility" />                                                   
                            <div id="passwordError" class="text-left newErrorMsgTxt signInErrorText" v-if="error.password">
                                {{ error.password }}
                            </div>
                        </div>
                    </div>

                    <div class="rememberForgot">
                        <div class="checkboxRemember">
                            <input type="checkbox" class="signUpCheckBox signInCheckbox" v-model="rememberMe" id="exampleCheck12" @change="updateRememberMe">
                            <p class="remembermeTxt mb-0">Remember me</p>
                        </div>
                        <p class="forgotPswdTxtNew mb-0" @click="forgot_password()">Forgot Password?</p>
                    </div>

                    <div class="d-flex justify-content-center newLoginBtnSec">
                        <button type="button" class="btn newLoginTxt disable" v-if="!isFormFilledSignIn"> Login </button>
                        <button type="button" class="btn newLoginTxt mwcButtonStyles" v-else @click="sign_in()" :disabled="isLoading"> Login </button>
                    </div>

                    <div id="apiError" class="mb-1 errorMsgStyles text-center" v-if="error.apiError" :style="{ color: 'red' }">
                        {{ error.apiError }}
                    </div>

                    <p class="otherLogins">Other log in options</p>
                    <div class="socialLogins">
                        <button type="button" class="btn socialLoginIconsNew" @click="facebook_signIn()"> <em class="fa-brands fa-facebook-f fbIcon"></em></button>
                        <button type="button" class="btn socialLoginIconsNew" @click="google_signIn()"> <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/googleImg.svg" alt="" class="newGoogleSignUpImg"></button>
                        <button type="button" class="btn socialLoginIconsNew" @click="apple_signIn()"> <em class="fa-brands fa-apple signUpAppleIcon"></em></button>
                    </div>

                    <div class="signupTextNewDesign">
                        Don't have account? <span class="signUpNowNew" @click="signup_now()"> Sign up </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<v-snackbar v-model="signUpSuccessSnackbar" :timeout="timeout" :color="color">
    <div class="text-center">
        {{ message }}
    </div>
</v-snackbar>
<v-snackbar v-model="loginSuccessSnackbar" :timeout="timeout" :color="color">
    <div class="text-center">
        {{ message }}
    </div>
</v-snackbar>
</template>

<script>
import {
    MwcMixin
} from "@/mixins/pathName.js";

import {
    mapGetters
} from 'vuex';
import '@/assets/css/signIn.css'
import '@/assets/css/global.css'
import navBar from '@/components/homeWithoutLogin/navBar.vue'
import vaildationMixin from "../../mixins/validation.js"
import scrollTop from '@/mixins/scrollTop.js'

export default {
    data() {
        return {
            rememberMe: false,
            loginSuccessSnackbar: false,
            negColor: "error",
            guestcartDetails: [],
            accordionOpen: false,
            signUpSuccessSnackbar: false,
            message: "",
            timeout: 3000,
            color: "success",
            isLoading: false,
            fullName: '',
            refCode: "",
            referralIdError: null,
            emailAddress: '',
            password: '',
            confirmPassword: '',
            dateOfBirth: '',
            checkSMS: false,
            checkOtp: false,
            payload: {},
            guestcartDetails: [],
            negColor: "error",
            error: {
                fullName: '',
                emailAddress: '',
                password: '',
                confirmPassword: '',
                dateOfBirth: '',
                apiError: '',
            },
            userSession: "",
            isPasswordVisible: false,
            isConfPasswordVisible: false,
            isfullNameActive: false,
            isfullNameFocused: false,
            isPasswordActive: false,
            isconfirmPasswordActive: false,
            isEmailActive: false,
            isEmailFocused: false,
            isPasswordFocused: false,
            isconfirmPasswordFocused: false,
            isDateOfBirthActive: false,
            isDateOfBirthFocused: false,
            correctInput: {
                email: false,
                password: false,
                confirmPassword: false,
                fullName: false,
                dateOfBirth: false,
            },
            isIOS: false

        }
    },
    components: {
        navBar
    },
    mixins: [vaildationMixin, scrollTop, MwcMixin],

    created() {
        this.userSession = localStorage.getItem("user_session");
        this.scrollTop()
        this.detectIOS();
    },
    mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.refCode = id;
            this.accordionOpen = true;
        }

        this.loadRememberedCredentials();
    },
    computed: {
        ...mapGetters(['getGuestAddCartData']),

        isFormFilled() {
            return (
                this.fullName != "" &&
                this.password != "" &&
                this.confirmPassword != "" &&
                this.emailAddress != "" &&
                this.error.fullName == "" &&
                this.error.emailAddress == "" &&
                this.error.password == "" &&
                this.error.confirmPassword == ""
            );
        },

        isFormFilledSignIn() {
            return (
                this.password != "" &&
                this.emailAddress != "" &&
                this.error.emailAddress == "" &&
                this.error.password == ""
            );
        },

    },

    methods: {
        backToHome() {
            this.$router.push({
                name: 'homeWithoutLogin',
            });
            this.clear_fields()
        },

        togglePasswordVisibility() {
            this.isPasswordVisible = !this.isPasswordVisible;
        },

        toggleConfPasswordVisibility() {
            this.isConfPasswordVisible = !this.isConfPasswordVisible;
        },

        go_to_cart() {
            this.$router.push({
                name: 'cartWithoutLogin',
            });
        },

        login_now() {
            this.loadRememberedCredentials();
            $("#signUpModal").modal("hide");
            $("#signInModal").modal("show");
        },

        clear_fields() {
            this.fullName = "";
            this.password = "";
            this.confirmPassword = "";
            this.emailAddress = "";
            this.dateOfBirth = "";
            this.checkOtp = false;
            this.error.fullName = "";
            this.error.emailAddress = "";
            this.error.password = "";
            this.error.confirmPassword = "";
            this.error.dateOfBirth = "";
            this.error.apiError = "";
            this.isPasswordVisible = false;
            this.isConfPasswordVisible = false;
            this.isfullNameActive = false;
            this.isfullNameFocused = false;
            this.isPasswordActive = false;
            this.isconfirmPasswordActive = false;
            this.isEmailActive = false;
            this.isEmailFocused = false;
            this.isPasswordFocused = false;
            this.isconfirmPasswordFocused = false;
            this.isDateOfBirthActive = false;
            this.isDateOfBirthFocused = false;
            this.correctInput.email = false;
            this.correctInput.password = false;
            this.correctInput.dateOfBirth = false;
            this.correctInput.fullName = false;
            this.rememberMe = false;
        },

        forgot_password() {
            this.$router.push("/forgotPassword")
        },

        signup_now() {
            $("#signInModal").modal("hide");
            $("#signUpModal").modal("show");
            this.clear_fields()
        },

        validate_inputs(field) {
            if (field === 'fullName') {
                let fullName = this.valid_fullName(this.fullName);
                if (!fullName.valid) {
                    this.error.fullName = fullName.error;
                    this.correctInput.fullName = false;
                    this.isfullNameFocused = true;

                } else {
                    this.error.fullName = '';
                    this.correctInput.fullName = true;
                    this.isfullNameFocused = false;
                    this.error.apiError = '';
                }
            } else if (field === 'emailAddress') {
                let emailAddress = this.validate_email(this.emailAddress);
                if (!emailAddress.valid) {
                    this.error.emailAddress = emailAddress.error;
                    this.isEmailFocused = true;
                    this.correctInput.email = false;
                } else {
                    this.error.emailAddress = '';
                    this.error.apiError = '';
                    this.isEmailFocused = false;
                    this.correctInput.email = true;
                }
            } else if (field === 'password') {
                let password = this.validate_password(this.password);
                if (!password.valid) {
                    this.error.password = password.error;
                    this.isPasswordFocused = true;
                    this.correctInput.password = false;
                } else {
                    this.error.password = '';
                    this.isPasswordFocused = false;
                    this.correctInput.password = true;
                    this.error.apiError = '';
                }
            } else if (field === 'confirmPassword') {
                let confirmPassword = this.validate_confirmPassword(this.password, this.confirmPassword);
                if (!confirmPassword.valid) {
                    this.error.confirmPassword = confirmPassword.error;
                    this.isconfirmPasswordFocused = true;
                    this.correctInput.confirmPassword = false;
                } else {
                    this.error.confirmPassword = '';
                    this.isconfirmPasswordFocused = false;
                    this.correctInput.confirmPassword = true;
                    this.error.apiError = '';
                }
            } else if (field === 'dateOfBirth') {
                let dateOfBirth = this.valid_dateOfBirth(this.dateOfBirth);
                if (!dateOfBirth.valid) {
                    this.error.dateOfBirth = dateOfBirth.error;
                    this.correctInput.dateOfBirth = false;
                    this.isDateOfBirthFocused = true;
                } else {
                    this.error.dateOfBirth = '';
                    this.correctInput.dateOfBirth = true;
                    this.isDateOfBirthFocused = false;
                    this.error.apiError = '';
                }
            }

        },

        validate_referral() {
            this.referralIdError = ""
        },

        sign_up() {
            if (this.isFormFilled) {
                this.signUp();

                if (this.isMwcPath()) {
                    dataLayer.push({
                        'event': 'sign_up_started_MWC'
                    });
                }
            }
        },

        async signUp() {
            let finalSignUpData = {
                email: this.emailAddress.trim(),
                full_name: this.fullName.trim(),
                data_of_birth: this.dateOfBirth,
                password: this.password,
                confirmation_password: this.confirmPassword,
                emailOpt: this.checkOtp,
                referrerId: this.refCode,
            };
            try {
                this.isLoading = true;
                await this.$store.dispatch("sign_up", finalSignUpData);
                const signUpData = this.$store.getters.getSignUpData;

                if (signUpData.status == 200) {
                    this.signUpSuccessSnackbar = true;
                    this.message = "Sign Up Successful"

                    this.clear_fields()
                    localStorage.setItem("user_session", JSON.stringify(signUpData.data[0].access_token));
                    const addToCartCheckWithoutLogin = (sessionStorage.getItem("adding_toCart"));
                    const openAccountReferral = localStorage.getItem('openReferral');
                    const openAccountReferralMob = localStorage.getItem('openReferralMob');
                    const openAccount = localStorage.getItem('routeToAccounts');

                    if (this.isMwcPath()) {
                        dataLayer.push({
                            'event': 'sign_up_completed',
                            'value': 'MWC',
                        });
                    }
                    if (addToCartCheckWithoutLogin === "true") {
                        this.userSession = localStorage.getItem("user_session");
                        this.call_addto_cart(this.userSession);
                    } else if (openAccountReferral === "true") {
                        this.$router.push('/accountsPage')
                        sessionStorage.setItem("openReferAndEarn", true)
                        localStorage.removeItem('openReferral')
                    } else if (openAccountReferralMob === 'true') {
                        this.$router.push('/referAndEarn')
                        localStorage.removeItem('openReferralMob')
                    } else if (openAccount === 'true') {
                        this.$router.push('/accountsPage')
                        localStorage.removeItem('routeToAccounts')
                    } else {
                        setTimeout(() => {
                            this.$router.push({
                                name: 'homeWithLogin',
                            });
                        }, 2000);
                    }

                    this.isLoading = false;

                } else if (signUpData === "Invalid Request") {
                    this.error.apiError = "Please enter the valid details";
                    this.isLoading = false;
                } else if (signUpData.status == 400) {
                    if (signUpData.message === "No refferral ID found") {
                        this.referralIdError = "Enter valid refferral ID";
                        this.isLoading = false;
                    } else {
                        this.error.apiError = signUpData.message
                        this.isLoading = false;
                    }
                } else if (signUpData.status == 500) {
                    this.error.apiError = signUpData.message
                    this.isLoading = false;
                } else {
                    this.error.apiError = "Something went wrong. Please try again";
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
            }

        },

        call_addto_cart(userSession) {
            const parts = userSession.split('.');
            this.payload = JSON.parse(atob(parts[1]));
            this.cart_details = JSON.parse(sessionStorage.getItem('guest_cartDetails'));

            if (this.cart_details) {
                this.cart_details.forEach(item => {
                    let newItem = {
                        raffle_id: item.raffle_id,
                        ticket_count: item.finalTickets,
                        answer: item.QuestAnswer
                    };
                    this.guestcartDetails.push(newItem);

                });

                this.add_cart_data(this.guestcartDetails, this.cart_details, userSession)
            } else {
                this.$router.push({
                    name: 'homeWithLogin',
                });
            }

        },

        async add_cart_data(items, cart_details, userSession) {
            try {
                await this.$store.dispatch('guestAddToCart', {
                    userSession: userSession,
                    items: items,
                    user_id: this.payload.request.user_id
                });
                this.addCartData = (this.getGuestAddCartData);
                if (this.addCartData == 200) {
                    sessionStorage.removeItem('guest_cartDetails')
                    sessionStorage.removeItem('adding_toCart');
                    this.$router.push({
                        name: 'cartWithLogin',
                    });
                    const itemsDataArray = [];
                    for (let i = 0; i < cart_details.length; i++) {
                        const item = cart_details[i];
                        const itemsData = {
                            item_id: item.raffle_id,
                            item_name: item.title,
                            item_category: '',
                            price: item.entry_cost_gbp,
                            quantity: item.finalTickets
                        };

                        itemsDataArray.push(itemsData);
                    }

                    if (this.isMwcPath()) {
                        dataLayer.push({
                            event: "add_to_cart",
                            ecommerce: {
                                items: itemsDataArray
                            }
                        });
                    }

                } else {
                    localStorage.setItem("negativeSnackBar", this.addCartData.message)
                    this.$router.push({
                        name: 'cartWithLogin',
                    });
                }

            } catch (error) {

            }
        },

        async google_signUp() {
            if (this.isMwcPath()) {
                dataLayer.push({
                    'event': 'sign_up_started_google'
                });
            }
            try {
                await this.$store.dispatch("google_sign_up");
                const googleSignUpData = this.$store.getters.getGoogleSignUpData;

                if (googleSignUpData.status == 200) {
                    window.location.href = googleSignUpData.redirectUrl;

                    if (this.isMwcPath()) {
                        dataLayer.push({
                            'event': 'sign_up_completed',
                            'value': 'google',
                        });
                    }

                } else {
                    console.error("Error :", error);
                }

            } catch (error) {
                console.error("Error :", error);
            }
        },

        detectIOS() {
            // if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            this.isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
        },

        async apple_signUp() {
            if (this.isMwcPath()) {
                dataLayer.push({
                    'event': 'sign_up_started_apple'
                });
            }
            try {
                await this.$store.dispatch("apple_sign_up");
                const appleSignUpData = this.$store.getters.getAppleSignUpData;

                if (appleSignUpData.status == 200) {
                    window.location.href = appleSignUpData.redirectUrl;

                    if (this.isMwcPath()) {
                        dataLayer.push({
                            'event': 'sign_up_completed',
                            'value': 'apple',
                        });
                    }

                } else {
                    console.error("Error :", error);
                }

            } catch (error) {
                console.error("Error :", error);
            }
        },

        async facebook_signUp() {
            if (this.isMwcPath()) {
                dataLayer.push({
                    'event': 'sign_up_started_facebook'
                });
            }
            try {
                await this.$store.dispatch("facebook_sign_up");
                const facebookSignUpData = this.$store.getters.getFacebookSignUpData;
                if (facebookSignUpData.status == 200) {
                    window.location.href = facebookSignUpData.redirectUrl;
                    if (this.isMwcPath()) {
                        dataLayer.push({
                            'event': 'sign_up_completed',
                            'value': 'facebook',
                        });
                    }
                } else {
                    console.error("Error :", error);
                }
            } catch (error) {
                console.error("Error :", error);
            }
        },

        handleFocus(field) {
            if (field === "email") {
                this.isEmailActive = true;
                this.isEmailFocused = true;
            } else if (field === "password") {
                this.isPasswordActive = true;
                this.isPasswordFocused = true;
            } else if (field === "dateOfBirth") {
                this.isDateOfBirthActive = true;
                this.isDateOfBirthFocused = true;
            } else if (field === "confirmPassword") {
                this.isconfirmPasswordActive = true;
                this.isconfirmPasswordFocused = true;
            } else if (field === "fullName") {
                this.isfullNameActive = true;
                this.isfullNameFocused = true;
            }
        },

        handleBlur(field) {
            if (field === "email" && !this.emailAddress) {
                this.isEmailActive = false;
                this.isEmailFocused = false;
            } else if (field === "password" && !this.password) {
                this.isPasswordActive = false;
                this.isPasswordFocused = false;
            } else if (field === "dateOfBirth" && !this.dateOfBirth) {
                this.isDateOfBirthActive = false;
                this.isDateOfBirthFocused = false;
            } else if (field === "confirmPassword" && !this.confirmPassword) {
                this.isconfirmPasswordActive = false;
                this.isconfirmPasswordFocused = false;
            } else if (field === "fullName" && !this.fullName) {
                this.isfullNameActive = false;
                this.isfullNameFocused = false;
            }
        },

        formatDateOfBirth(event) {
            let value = event.target.value.replace(/\D/g, '');
            if (value.length > 8) value = value.slice(0, 8);
            if (value.length >= 3 && value.length <= 4) {
                value = value.slice(0, 2) + '/' + value.slice(2);
            } else if (value.length > 4) {
                value = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4);
            }

            this.dateOfBirth = value;
            this.validate_inputs('dateOfBirth');
        },

        // signin functionalities

        sign_in() {
            if (this.isFormFilledSignIn) {
                this.signIn();
            }
        },

        async signIn() {
            let finalSignInData = {
                email: this.emailAddress.trim(),
                password: this.password,
            };
            try {
                this.isLoading = true;
                await this.$store.dispatch("sign_in", finalSignInData);
                const signInData = this.$store.getters.getSignInData;
                if (signInData.status == 200) {
                    this.clear_fields()
                    this.loginSuccessSnackbar = true;
                    this.message = "Login Successful"
                    localStorage.setItem("user_session", JSON.stringify(signInData.data[0].access_token));
                    const addToCartCheckWithoutLogin = (sessionStorage.getItem("adding_toCart"));
                    const openAccountReferral = localStorage.getItem('openReferral');
                    const openAccountReferralMob = localStorage.getItem('openReferralMob');
                    const openAccount = localStorage.getItem('routeToAccounts');
                    if (this.isMwcPath()) {
                        dataLayer.push({
                            'event': 'sign_in_completed'
                        });
                    }
                    if (addToCartCheckWithoutLogin === "true") {
                        this.userSession = localStorage.getItem("user_session");
                        this.call_addto_cart(this.userSession);
                    } else if (openAccountReferral === 'true') {
                        this.$router.push('/accountsPage')
                        sessionStorage.setItem("openReferAndEarn", true)
                        localStorage.removeItem('openReferral')
                    } else if (openAccountReferralMob === 'true') {
                        this.$router.push('/referAndEarn')
                        localStorage.removeItem('openReferralMob')
                    } else if (openAccount === 'true') {
                        this.$router.push('/accountsPage')
                        localStorage.removeItem('routeToAccounts')
                    } else {
                        setTimeout(() => {
                            this.$router.push({
                                name: 'homeWithLogin',
                            });
                        }, 2000);
                    }
                    this.isLoading = false;
                } else if (signInData === "This Account is Blocked") {
                    this.error.apiError = "This Account is Blocked"
                    this.isLoading = false;
                } else if (signInData.status == 400) {
                    this.error.apiError = signInData.message
                    this.isLoading = false;
                } else if (signInData.status == 500) {
                    this.error.apiError = signInData.message
                    this.isLoading = false;
                } else {
                    this.error.apiError = "Something went wrong. Please try again";
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
            }
        },

        async google_signIn() {
            try {
                await this.$store.dispatch("google_sign_in");
                const googleSignInData = this.$store.getters.getGoogleSignInData;
                if (googleSignInData) {
                    window.location.href = googleSignInData.redirectUrl;
                }else {
                    console.error("Error :", error);
                }
            } catch (error) {}
        },

        async facebook_signIn() {
            try {
                await this.$store.dispatch("facebook_sign_in");
                const facebookSignInData = this.$store.getters.getFacebookSignInData;

                if (facebookSignInData.status == 200) {
                    window.location.href = facebookSignInData.redirectUrl;
                } else {
                    console.error("Error :", error);
                }
            } catch (error) {
                console.error("Error :", error);
            }
        },

        async apple_signIn() {
            try {
                await this.$store.dispatch("apple_sign_in");
                const appleSignInData = this.$store.getters.getAppleSignInData;
                if (appleSignInData.status == 200) {
                    window.location.href = appleSignInData.redirectUrl;
                }
            } catch (error) {}
        },

        // remember me functionality

        // Set a cookie with a name, value, and expiration in days
        setCookie(name, value, days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `${name}=${value}; ${expires}; path=/`;
        },

        // Get a cookie value by name
        getCookie(name) {
            const cookies = document.cookie.split('; ');
            for (const cookie of cookies) {
                const [key, value] = cookie.split('=');
                if (key === name) return value;
            }
            return null;
        },

        // Delete a cookie by name
        deleteCookie(name) {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        },

        // Update cookies based on "Remember Me" selection
        updateRememberMe() {
            if (this.rememberMe) {
                this.setCookie('rememberMeEmail', this.emailAddress, 7);
                this.setCookie('rememberMePassword', this.password, 7);
            } else {
                this.deleteCookie('rememberMeEmail');
                this.deleteCookie('rememberMePassword');
            }
        },

        // Load remembered credentials from cookies
        loadRememberedCredentials() {
            const rememberedEmail = this.getCookie('rememberMeEmail');
            const rememberedPassword = this.getCookie('rememberMePassword');
            if (rememberedEmail) {
                this.emailAddress = rememberedEmail;
                this.rememberMe = true;
                this.$nextTick(() => {
                    this.validate_inputs('emailAddress');
                    this.isEmailActive = true;
                });
            }
            if (rememberedPassword) {
                this.password = rememberedPassword;
                this.$nextTick(() => {
                    this.validate_inputs('password');
                    this.isPasswordActive = true;
                });
            }
        },

    }

}
</script>

<style>
.modal-backdrop.fade {
    opacity: 0 !important;
}

.modal-backdrop {
    display: none !important;
}
</style>
