import { apiURL } from "@/allApi/api";

const state = {
  winnersCarouselData: [],
  allRafflesData: [],
  allRafflesDataWithLogin : [],
  welcomBackground : [],
  joinMailData : [],
  checkMailData : []
};

const getters = {

  getwinnersCarouselData: (state) => {
    return state.winnersCarouselData;
  },

  getAllRafflesData: (state) => {
    return state.allRafflesData;
  },

  getAllRafflesWithLoginData : (state) => {
    return state.allRafflesDataWithLogin;
  },

  getWelcomBackgroundData : (state) => {
    return state.welcomBackground;
  },
  getJoinMailData : (state) => {
    return state.joinMailData;
  },
  getCheckMailData : (state) => {
    return state.checkMailData;
  }
  
};

const mutations = {
  set_fetch_winnersCarousel(state, data) {
    state.winnersCarouselData = data;
  },
  set_all_raffles: (state, data) => {
    state.allRafflesData = data;
  },

  set_with_login_all_raffles: (state, data) => {
    state.allRafflesDataWithLogin = data;
  },
  
  set_welcom_Background: (state, data) => {
    state.welcomBackground = data;
  },
  set_joinMail_data: (state, data) => {
    state.joinMailData = data;
  },
  set_checkMailSub_data: (state, data) => {
    state.checkMailData = data;
  }
};

const actions = {
  async fetchWinnersCarousel({ commit }) {
    try {
      const response = await fetch(apiURL.winnersCarousel, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await response.json();
      commit("set_fetch_winnersCarousel", data);
    } catch (error) {
      console.error("Error fetching featured raffle:", error);
    }
  },

  async fetchAllRaffles({ commit }) {
    try {
      const response = await fetch(apiURL.allRaffles, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await response.json();
      commit("set_all_raffles", data);
    } catch (error) {
      console.error("Error fetching all raffles:", error);
    }
  },

async fetchAllRafflesWithLogin({ commit }, userSession) {
  try{
    const response = await fetch(apiURL.allRafflesWithLogin, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: JSON.parse(userSession),
      },
    });
    const data = await response.json();
    commit('set_with_login_all_raffles', data);
  } catch (error) {
    console.error("Error fetching all raffles:", error);
  }
},



async fetchWelcomBackground({ commit }) {
  try{
    const response = await fetch(apiURL.welcomBackground, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await response.json();
    commit('set_welcom_Background', data);
  } catch (error) {
    console.error("Error fetching welcom Background:", error);
  }
},
async join_mail({ commit }, email) {
  try {
    const response = await fetch(apiURL.mailSubscribe, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(email),
    });
    const data = await response.json();
    commit("set_joinMail_data", data);
  } catch (error) {
    console.error("Error:", error);
  }
},
async check_email({ commit }, email) {
  try {
    const response = await fetch(apiURL.checkMailSubscribe, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(email),
    });
    const data = await response.json();
    commit("set_checkMailSub_data", data);
  } catch (error) {
    console.error("Error:", error);
  }
},


};

export default {
  state,
  mutations,
  actions,
  getters,
};
