export default {
    methods: {
        valid_inputs(value) {
            if (value == undefined || value.trim().length == 0) {
                return { valid: false, error: "This field is Required" }
            }
            else if (!/^[a-zA-Z0-9]+$/.test(value.trim())) {
                return { valid: false, error: "Value should only contain alphanumeric characters" };
            }
            else if (value.trim().length >= 50) {
                return { valid: false, error: "value should be less than 50 characters" }
            }
            else{
                return { valid: true, error: '' }
            }
        },
        
        validate_email(value) {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (value.trim() == undefined || value.trim().length == 0) {
                return { valid: false, error: "Email is required" }
            }
            else if (!emailPattern.test(value.trim())) {
                return { valid: false, error: "Please enter a valid email address" }
            }
            else if (value.trim().length >= 150) {
                return { valid: false, error: "value should be less than 150 characters" }
            }
            else{
                return { valid: true, error: '' }
            }
        },

        validate_phone(value) {
            const phonePattern = /^[0-9]+$/
            if (value.trim() == undefined || value.trim().length == 0) {
                return { valid: false, error: "Please enter phone number" }
            }
            else if (!phonePattern.test(value.trim())) {
                return { valid: false, error: "Contact number should contain only numbers" }
            } 
            else if (value.trim().toString().length < 10 || value.trim().toString().length > 11) {
                return { valid: false, error: "Contact number must be 10 or 11 digits" }
            } 
            else {
                return { valid: true, error: '' }
            }
        },


        validate_password(value) {

            if (value == undefined || value.trim().length == 0) {
                return { valid: false, error: "Password is required"}
            }
            else if (value.length > 30) {
                return { valid: false, error: "Password must be less than 30 characters" }
            } 
            else if (value.trim().length < 6) {
                return { valid: false, error: "Password must be atleast 6 characters" }
            } 
            else {
                return { valid: true, error: '' }
            }
        },
        validate_confirmPassword(password, confirmPassword) {
            if (!confirmPassword || confirmPassword.length === 0) {
              return { valid: false, error: 'Confirm Password is required' };
            } else if (confirmPassword !== password) {
              return { valid: false, error: 'Passwords do not match' };
            } else {
              return { valid: true, error: '' };
            }
          },
        valid_fullName(value){
            if (value === undefined || value.trim().length === 0) {
                return { valid: false, error: "Full name field is required" };
            } 
            else if (!/^[a-zA-Z\s]+$/.test(value.trim())) {
                return { valid: false, error: "Full name should only contain alphabetic characters and spaces" };
            } 
            else if (value.trim().length > 50) {
                return { valid: false, error: "Full name should be less than or equal to 50 characters" };
            } 
            else {
                return { valid: true, error: '' };
            }
        },
          
            valid_dateOfBirth(value) {
                // Validation logic remains the same as before
                const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
                const match = value.trim().match(regex);
        
                if (!match) {
                    return { valid: false, error: "Invalid date format. Use DD/MM/YYYY." };
                }
        
                const day = parseInt(match[1], 10);
                const month = parseInt(match[2], 10) - 1;
                const year = parseInt(match[3], 10);
        
                const inputDate = new Date(year, month, day);
                if (
                    inputDate.getFullYear() !== year ||
                    inputDate.getMonth() !== month ||
                    inputDate.getDate() !== day
                ) {
                    return { valid: false, error: "Invalid date provided." };
                }
        
                const today = new Date();
                const age = today.getFullYear() - inputDate.getFullYear();
                const isBirthdayPassed =
                    today.getMonth() > month || (today.getMonth() === month && today.getDate() >= day);
                const exactAge = isBirthdayPassed ? age : age - 1;
        
                if (exactAge < 18) {
                    return { valid: false, error: "Age must be 18 years or older." };
                }
        
                return { valid: true, error: '' };
            },
        
        
        
        
    }
}
