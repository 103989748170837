<template>
<div class="otherRaffleNewBg">
    <!-- <div class="otherRaffIntrestedTxt">
        Other raffles you may be interested in...
    </div> -->
    <div class="otherRafflesBg">
        <div class="otherRafflesText otherRaffIntrestedTxt">
            Other raffles you may be interested in...
        </div>

        <div class="otherRafflesSection">
            <div class="row p-0 otherRaffleSecData">
                <div class="col-6 p-0 otherRaffleStle" v-for="item in allRaffleData" :key="item">
                    <div class="raffleImageSec" :style="{  background: `linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%), url('${urlLink + item.mini_image_url}')`,backgroundSize: 'cover', backgroundPosition: 'center' }">
                        <div class="raffleSecDetails">
                            <h6 class="otherRaffleDrawtxt">Time until draw:</h6>
                            <div class="d-flex flex-column align-items-center">
                                <div class="mwcDaysTxtBg">
                                    <div>
                                        <p class="otherRaffleDrawTimer mb-0">
                                            {{ countdownDays[item.raffle_id] }}
                                        </p>
                                        <h6 class="otherRaffleTimerText mb-0">Days</h6>
                                    </div>
                                    <div>
                                        <p class="otherRaffleDrawTimer mb-0">
                                            {{ countdownHours[item.raffle_id] }}
                                        </p>
                                        <h6 class="otherRaffleTimerText mb-0">Hours</h6>
                                    </div>
                                    <div>
                                        <p class="otherRaffleDrawTimer mb-0">
                                            {{ countdownMinutes[item.raffle_id] }}
                                        </p>
                                        <h6 class="otherRaffleTimerText mb-0">Minutes</h6>
                                    </div>
                                    <div>
                                        <p class="otherRaffleDrawTimer mb-0">
                                            {{ countdownSeconds[item.raffle_id] }}
                                        </p>
                                        <h6 class="otherRaffleTimerText mb-0">Seconds</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center otherRafflesDetails">
                                <div class="rafflePrizeAndTitle">
                                    <div class="otherRafflePrize">
                                        <p class="otherRafflePrizeDetails">£{{ item.entry_cost_gbp }} per ticket</p>
                                    </div>
                                    <div class="otherRaffleText newDescTxtEllipsis">
                                        {{ item.title }}
                                    </div>
                                </div>
                                <div>
                                    <button type="button" class="viewDetailsButton mwcButtonStyles" @click="routeToRaffles(item)">
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
</template>

<script>
import {
    urlLink,
    socialImgUrlLink
} from '@/mixins/s3bucket.js'
import {
    mapGetters
} from 'vuex';
import timer from '@/mixins/timer';
export default {
    mixins: [timer],
    data() {
        return {
            allRaffleData: [],
            urlLink: urlLink,
            socialImgUrlLink: socialImgUrlLink,
            countdownIntervals: {}, // For storing intervals by raffle_id
            countdownDays: {},
            countdownHours: {},
            countdownMinutes: {},
            countdownSeconds: {},
        }
    },
    computed: {
        ...mapGetters(['getAllRafflesData'])
    },

    created() {
        this.all_raffles()
    },
    methods: {
        async all_raffles() {
            try {
                await this.$store.dispatch('fetchAllRaffles');
                this.allRaffleData = this.getAllRafflesData.allRaffles.slice(0, 2);
                this.startCountdownMultiple(this.allRaffleData);
                // this.startCountdown(this.allRaffleData);
                // this.startCountdown7days(this.allRaffleData);
            } catch (error) {
                console.error("Error fetching all raffles:", error);
            }
        },

        routeToRaffles(item) {
            this.idArray = item;

            let searchParams = window.location.href;
            if (searchParams.includes("raffleDetails")) {
                this.$router.push({
                    name: 'raffleDetailsWithoutLogin',
                    params: {
                        id: this.idArray.raffle_id.split('Raffle#')[1],
                    }
                }).then(() => {
                    this.$router.go(0);
                });
            } else {
                this.$router.push({
                    name: 'raffleDetailsWithoutLogin',
                    params: {
                        id: this.idArray.raffle_id.split('Raffle#')[1],
                    }
                });
            }
        },
    }
}
</script>
