<template>
  <div>
    <div class="mwcHowToPlaySecBg">
      <h2 class="mwcHowToPlayTxt">
        How to Play: Tips to Maximise Your Chances
      </h2>
      <p class="mwcHowToPlayDescTxt">
        With simple steps and a little bit of luck, your dream time-piece could
        be just a ticket away!
      </p>
      <swiper
        class="mwcPlaySecSteps swiperCardsHide"
        ref="swiper"
        @swiper="onSwiper"
        :slides-per-view="getSlidesPerView"
        :space-between="10"
        :breakpoints="breakpoints"
        navigation
        :loop="false"
        @slideChange="onSlideChange"
      >
        <swiper-slide class="mwcPlaySecOptionsBg justify-content-end">
          <p class="mwcPlayLeftSecIconBg">
            <em class="fa-light fa-ticket mwcPlaySecTktIcon"></em>
          </p>
          <p class="howToPlayTxt">STEP 1</p>
          <p class="mwcPlaySecChooseTktTxt mb-0">
            Answer the question and then select your tickets (we offer generous
            discounts on larger ticket bundles)
          </p>
        </swiper-slide>
        <swiper-slide
          class="mwcPlaySecOptionsBg justify-content-end mwcPlaySecTwoBg"
        >
          <p class="mwcPlayLeftSecIconBg">
            <em class="fa-light fa-bag-shopping mwcPlayBagIcon"></em>
          </p>
          <p class="howToPlayTxt">STEP 2</p>
          <p class="mwcPlaySecChooseTktTxt mwcPlayScndStpTxt">
            Purchase your tickets and receive your ticket numbers for the raffle
          </p>
        </swiper-slide>
        <swiper-slide class="mwcPlaySecOptionsBg justify-content-end">
          <p class="mwcPlayLeftSecIconBg">
            <em class="fa-light fa-watch mwcWatchIcon"></em>
          </p>
          <p class="howToPlayTxt">STEP 3</p>
          <p class="mwcPlaySecChooseTktTxt howToPlayStepThreeTxt">
            Watch the live draw on our social media channels to see if your
            ticket is a winner!
          </p>
        </swiper-slide>
        <swiper-slide
          class="mwcPlaySecOptionsBg justify-content-end mwcPlayStepFourBg"
        >
          <p class="mwcPlayLeftSecIconBg">
            <em class="fa-sharp fa-light fa-coin mwcPlayCoinIcon"></em>
          </p>
          <p class="howToPlayTxt">STEP 4</p>
          <p class="mwcPlaySecChooseTktTxt mb-0">
            Earn £20 MWC credit for every successful referral you make! <span class="findMoreTxtLink findMoreWeb mb-0" @click="get_referal_link()">
            Find out more</span>
          </p>
          <p
            class="findMoreTxtLink findMoreMob mb-0"
            @click="get_referal_link_mob()"
          >
            Find out more
          </p>
        </swiper-slide>
      </swiper>
      <div class="mwcPlaySecSteps howToPlayCardsHide">
        <div class="mwcPlaySecOptionsBg justify-content-end">
          <p class="mwcPlayLeftSecIconBg">
            <em class="fa-light fa-ticket mwcPlaySecTktIcon"></em>
          </p>
          <p class="howToPlayTxt">STEP 1</p>
          <p class="mwcPlaySecChooseTktTxt mb-0">
            Answer the question and then select your tickets (we offer generous
            discounts on larger ticket bundles)
          </p>
        </div>
        <div class="mwcPlaySecOptionsBg justify-content-end">
          <p class="mwcPlayLeftSecIconBg">
            <em class="fa-light fa-bag-shopping mwcPlayBagIcon"></em>
          </p>
          <p class="howToPlayTxt">STEP 2</p>
          <p class="mwcPlaySecChooseTktTxt mwcPlayScndStpTxt">
            Purchase your tickets and receive your ticket numbers for the raffle
          </p>
        </div>
        <div class="mwcPlaySecOptionsBg justify-content-end">
          <p class="mwcPlayLeftSecIconBg">
            <em class="fa-light fa-watch mwcWatchIcon"></em>
          </p>
          <p class="howToPlayTxt">STEP 3</p>
          <p class="mwcPlaySecChooseTktTxt howToPlayStepThreeTxt mb-0">
            Watch the live draw on our social media channels to see if your
            ticket is a winner!
          </p>
        </div>
        <div class="mwcPlaySecOptionsBg justify-content-end mwcPlayStepFourBg">
          <p class="mwcPlayLeftSecIconBg">
            <em class="fa-sharp fa-light fa-coin mwcPlayCoinIcon"></em>
          </p>
          <p class="howToPlayTxt">STEP 4</p>
          <p class="mwcPlaySecChooseTktTxt mb-0">
            Earn £20 MWC credit for every successful referral you make! <span  class="findMoreTxtLink findMoreWeb mb-0" @click="get_referal_link()">Find out more</span>
          </p>
          <p
            class="findMoreTxtLink findMoreMob mb-0"
            @click="get_referal_link_mob()"
          >
            Find out more
          </p>
        </div>
      </div>
      <div class="HowToPlayDotsSec">
        <p
          v-for="(dot, index) in 4"
          :key="index"
          class="howToPlayDots"
          :class="{
            'active-dot': activeDotIndex === index,
            [`howToPlayDots-${index}`]: true,
          }"
          @click="onDotClick(index)"
        ></p>
      </div>
    </div>
  </div>
</template>
      
      <script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
export default {
  data() {
    return {
      activeDotIndex: 0,
      swiperInstance: null,
      isBeginning: true,
      isEnd: false,
      userSession: "",
      breakpoints: {
        320: { slidesPerView: 1.2, spaceBetween: 0, loop: false },
        480: { slidesPerView: 2, spaceBetween: 0, loop: false },
        768: {
          slidesPerView: 1.2,
          spaceBetween: 0,
          loop: false,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
          loop: false,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    getSlidesPerView() {
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      if (isMobile) {
        if (this.activeDotIndex === 0) {
          return 1;
        }
        return 1;
      } else {
        return 4;
      }
    },
  },
  created() {
    this.userSession = localStorage.getItem("user_session");
  },
  methods: {
    get_referal_link() {
      if (this.userSession) {
        this.$router.push("/accountsPage");
        sessionStorage.setItem("openReferAndEarn", true);
      } else {
        this.$router.push("/signUp");
        localStorage.setItem("openReferral", true);
      }
    },
    get_referal_link_mob() {
      if (this.userSession) {
        this.$router.push("/referAndEarn");
      } else {
        this.$router.push("/signUp");
        localStorage.setItem("openReferralMob", true);
      }
    },
    onSlideChange(swiper) {
      this.isBeginning = swiper.isBeginning;
      this.isEnd = swiper.isEnd;
      this.activeDotIndex = swiper.realIndex;

      const minWidth = 320;
      const maxWidth = 479;
      const windowWidth = window.innerWidth;

      if (windowWidth >= minWidth && windowWidth <= maxWidth) {
        let defaultTransformValues = [0, 190, 420, 628];
        if (windowWidth <= 360) {
          if (this.activeDotIndex === 1) {
            swiper.translateTo(-190, 0, false);
          }
        } else if (windowWidth <= 390) {
          defaultTransformValues = [0, 170, 395, 610];
          if (this.activeDotIndex === 1) {
            swiper.translateTo(-170, 0, false);
          }
        } else if (windowWidth <= 440) {
          defaultTransformValues = [0, 140, 375, 600];
          if (this.activeDotIndex === 1) {
            swiper.translateTo(-140, 0, false);
          }
        }

        swiper.snapGrid = [...defaultTransformValues];
        swiper.slidesGrid = [...defaultTransformValues];

        console.log({ firsttttt: swiper });
      }

      console.log("Active Index:", this.activeDotIndex);
    },
    onDotClick(index) {
      this.activeDotIndex = index;
      console.log("Clicked Dot Index:", index);
      if (this.swiperInstance) {
        console.log(
          "Swiper Slides Per View:",
          this.swiperInstance.params.slidesPerView
        );
        this.swiperInstance.slideTo(index, 300);
      }
    },
    onSwiper(swiperInstance) {
      this.swiperInstance = swiperInstance;
    },
  },
};
</script>
      
      <style>
/* swiper css */
.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.mwcPlaySecOptionsBg {
  width: 270px !important;
  height: 324px !important;
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 25px 44px 24px;
}
.mwcPlayStepFourBg {
  padding: 0px 25px 24px 24px;
}
.swiper-button-next:after {
  content: "";
}
.swiper-button-prev:after {
  content: "";
}
@media (max-width: 767px) {
  /* .swiper-slide {
              flex: 0 0 calc(100% / 1.44);
              /* max-width: calc(100% / 1.25);
          } */
}
@media (min-width: 320px) and (max-width: 479px) {
  /* .swiper-slide {
              flex: 0 0 calc(100% / 1);
              max-width: calc(100% / 1);
          } */

  .mwcPlaySecOptionsBg {
    width: 211px !important;
    height: 267px !important;
    padding: 24px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .swiper-slide {
    /* flex: 0 0 calc(100% / 2.2); */
    /* max-width: calc(100% / 1); */
  }
  .mwcPlaySecOptionsBg {
    width: 211px !important;
    height: 267px !important;
    padding: 24px;
  }
  .howToPlayDots-3 {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 959px) {
  .mwcPlaySecOptionsBg {
    width: 353px !important;
    height: 231px !important;
    padding: 24px;
    margin-bottom: 8px;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  .mwcPlaySecOptionsBg {
    width: 353px !important;
    height: 231px !important;
    padding: 24px;
    margin-bottom: 8px;
  }
}

/* swiper css */
</style>  