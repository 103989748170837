<template>
<div>
    <div class="mwcSocialLoginSecBg">
        <h5 class="mwcFollowUsTxt">FOLLOW US</h5>
        <div class="mwcSocialSecImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcFbImg.svg" alt="" class="cursor-pointer footerSocialImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcTwitterImg.svg" alt="" class="cursor-pointer footerSocialImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcInstagramImg.svg" alt="" class="cursor-pointer footerSocialImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcTiktokImg.svg" alt="" class="cursor-pointer footerSocialImgs">
        </div>
    </div>
    <div class="footerSecBg">
        <p class="mwcFooterPaymentTxt">PAYMENTS WE ACCEPT</p>
        <div class="mwcFooterPayments">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcVisaImg.svg" alt="" class="footerSecPaymentImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcmasterCardImg.svg" alt="" class="footerSecPaymentImgs">
            <div class="mwcpaypalImgBg d-flex justify-content-center align-items-center mr-0 cursor-pointer">
                <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcPaypalImg.svg" alt="" class="paypalImgFooter">
            </div>
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcGpayImg.svg" alt="" class="footerSecPaymentImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcApplepayImg.svg" alt="" class="footerSecPaymentImgs">
        </div>
        <hr class="footerHr">
        <div class="footerCompetionsBg">
            <p class="footerAboutMwcTxt footerCompetitiontxt" @click="focusAllRaffles()">COMPETITIONS</p>
            <p class="footerAboutMwcTxt footerFaqTxt" @click="faq_page()">FAQs</p>
            <p class="footerAboutMwcTxt footerFaqTxt" @click="contact_us()">CONTACT US</p>
            <p class="footerAboutMwcTxt footerHowToPlaytxt" @click="how_to_Play()">HOW TO PLAY</p>
            <p class="footerAboutMwcTxt footerFaqTxt" @click="signUp()">MY ACCOUNT</p>
            <p class="footerAboutMwcTxt footerFaqTxt" @click="winners()">WINNERS</p>
        </div>
        <div class="row mob-footerAboutMwcSec">
            <div class="col-1"></div>
            <div class="col-5 text-center">
            <div class="footerAboutSecMargin">
                <p class="footerAboutMwcTxt footerCompetitiontxt" @click="focusAllRaffles()">COMPETITIONS</p>
                <p class="footerAboutMwcTxt footerFaqTxt" @click="faq_page()">FAQs</p>
                <p class="footerAboutMwcTxt footerFaqTxt mb-0" @click="contact_us()">CONTACT US</p>
                </div>
            </div>
            <div class="col-5 text-center">
            <div class="footerHowToPlayMargin">
                <p class="footerAboutMwcTxt footerHowToPlaytxt" @click="how_to_Play()">HOW TO PLAY</p>
                <p class="footerAboutMwcTxt footerFaqTxt" @click="signUp()">MY ACCOUNT</p>
                <p class="footerAboutMwcTxt footerFaqTxt" @click="winners()">WINNERS</p>
                </div>
            </div>
            <div class="col-1"></div>
        </div>
        <hr class="footerHr">
        <h6 class="footerCopyrightTxt">Copyright © 2023 Monaco Watch Club
 All rights reserved. Company No. 10274932</h6>
        <p class="footerTermsTxt"><span class="cursor-pointer" @click="privacy_page()">Privacy Policy</span> <span class="footerTermsSecHr">|</span> <span class="mob-policyTxtGap"></span> <span class="cursor-pointer" @click="terms_page()"> Terms of Service</span></p>
    </div>
</div>
</template>

<script>
import {
    MwcMixin
} from "@/mixins/pathName.js";
export default {

    mixins: [MwcMixin],

    methods: {
        contact_us() {
            this.$router.push("contactUs")

        },
        privacy_page() {
            this.$router.push("/privacyPolicy")
        },
        terms_page() {
            this.$router.push("/termsAndConditions")
        },
        faq_page() {
            this.$router.push("/faqs")
        },
        how_to_Play() {
            this.$router.push({
                name: 'howToPlayWithoutLogin',
            });

        },
        winners() {
            this.$router.push({
                name: 'winnersWithoutLogin',
            });
        },

        signUp() {
            if (this.isMwcPath()) {
                dataLayer.push({
                    'event': 'sign_up_accessed'
                });
            }
            this.$router.push("/signUp");
            localStorage.setItem("routeToAccounts", true)
        },

        focusAllRaffles() {
            if (this.$route.path === '/') {
                this.$emit('focusAllRaffles');
            } else {
                this.$router.push({
                    name: 'homeWithoutLogin',
                });
            }
        },

        as_seen_in() {
            if (this.$route.path === '/') {
                this.$emit('asSeenIn');
            } else {
                this.$router.push({
                name: 'homeWithoutLogin',
            });
            }
        },

    }
}
</script>
