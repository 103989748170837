<template>
<div>
    <div class="mb-welcomeSecHide">
        <video id="myVideo" v-if="welcomBackgroundVdo" width="100%" autoplay muted playsinline loop class="mwcWelcomeImg">
        <source :src="welcomBackgroundVdo" type="video/mp4">
            Your browser does not support the video tag.
           
            <!-- <div class="d-flex justify-content-center">
                <button type="button" class="btn mwcWelcomeEnterBtn" @click="enter_featured_raffle()">ENTER</button>
            </div> -->
        </video>
 <div class="d-flex justify-content-center">
                <img class="mwcWelcometxt" src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/monacoWatchClubImg.svg" alt="">
            </div>
    </div>

    <div class="web-welcomeSecHide position-relative">

        <video id="myVideo" class="mb-welcomeVideo" v-if="welcomBackgroundVdo" width="100%" height="468px" autoplay muted playsinline loop>
            <source :src="welcomBackgroundVdo" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div class="mob-WelcomePageVideoContent">
            <img class="MwcLogoImageHome" src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/monacoWatchClubImg.svg" alt="">
            <!-- <div class="d-flex justify-content-center">
                <button type="button" class="btn mwcWelcomeEnterBtn" @click="enter_featured_raffle()">ENTER</button>
            </div> -->
        </div>

    </div>
    <div class="mwcBestOddsBg">
        <h5 class="mwcBestOddTxt">“THE BEST ODDS WATCH COMPETITION AROUND” </h5>
        <div class="d-flex justify-content-center">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcStarRating.svg" alt="" class="mwcStarImg">
        </div>
        <p class="mwcGuardianTxt">THE GUARDIAN</p>
    </div>

</div>
</template>

<script>
import {
    urlLink,
    socialImgUrlLink
} from '@/mixins/s3bucket.js'
import {
    mapGetters
} from 'vuex';

export default {
    data() {
        return {
            welcomBackgroundImg: "",
            welcomBackgroundVdo: '',
            urlLink: urlLink,
            socialImgUrlLink: socialImgUrlLink,

        }
    },

    computed: {
        ...mapGetters(['getWelcomBackgroundData'])
    },

    created() {},
    mounted() {
        this.welcom_Background()
    },
    methods: {

        enter_featured_raffle() {
            this.$emit('enterFeaturedRaffle');
        },

        async welcom_Background() {
            try {
                await this.$store.dispatch('fetchWelcomBackground');

                let welcomBackgroundImgData = this.getWelcomBackgroundData;
                const welcomBackgroundImgObj = welcomBackgroundImgData.find(item => item.name === "HOME_PAGE_BANNER");
                if (welcomBackgroundImgObj) {
                    this.welcomBackgroundImg = welcomBackgroundImgObj.value;
                }

                let welcomBackgroundVdoData = this.getWelcomBackgroundData;
                const welcomBackgroundVdoObj = welcomBackgroundVdoData.find(item => item.name === "HOME_PAGE_BANNER_BACKGROUND_VIDEO");
                if (welcomBackgroundVdoObj) {
                    this.welcomBackgroundVdo = welcomBackgroundVdoObj.value;
                }

            } catch (error) {
                console.error("Error fetching all raffles:", error);
            }
        },
        playVideo() {
            var video = document.getElementById("myVideo");
            video.play();
        }

    }
}
</script>
