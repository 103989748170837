<template>
<div>
    <!-- tickets selector modal -->
    <div class="modalBackdropCustom modal fade" id="ticketSelector" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl newSelectTicketModal">
            <div class="modal-content newSelectorModalBg">
                <div class="modal-body newRaffTicketSelectorBg">
                    <div class="d-flex justify-content-between">
                        <p class="ticketSelectBackBtn mb-0" data-bs-dismiss="modal" @click="clear_tickets()"><em class="fa-light fa-arrow-left mobSelectorArrowIcon"></em>Back</p>
                        <h3 class="newMobTktSelHeadTxt">Select Tickets</h3>
                        <em class="fa-regular fa-xmark newRaffModalCloseIcon" data-bs-dismiss="modal" @click="clear_tickets()"></em>
                    </div>
                    <h3 class="newTktSelHeadTxt">Select Tickets</h3>
                    <div class="row">
                        <div class="col-3">
                            <img :src="urlLink + raffDetails.mini_image_url" alt="" class="TktSelRaffImg">
                        </div>
                        <div class="col-9 pl-0">
                            <div class="newMobRaffLeftMargin">
                                <p class="newRaffTktModalRaffTxt mb-0">{{ raffDetails.title }}</p>
                                <div class="d-flex justify-content-between">
                                    <p class="newRaffTktModalRaffPrice">£{{ raffDetails.entry_cost_gbp }} per ticket</p>
                                    <p class="newRaffTktModalRaffPrice newRaffTktModalRaffSold" v-if="!raffDetails.is_unlimited_entries">{{ raffDetails.tickets_sold }}/{{ raffDetails.total_entries }} sold</p>
                                </div>
                                <div class="d-flex newRaffMobProgressSec align-items-center" v-if="!raffDetails.is_unlimited_entries">
                                    <div class="raffModalOuterProgressBar">
                                        <div class="raffModalInnerProgressBar" :style="{ width: raffDetails.percentage_sold + '%' }" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                    <p class="newRaffTktModalRaffPrice newRaffTktModalRaffMobSold m-0" v-if="!raffDetails.is_unlimited_entries">{{ raffDetails.tickets_sold }}/{{ raffDetails.total_entries }} sold</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr class="ticketSelectorHrTag">
                    <p class="newRaffModalTktsTxt">How many tickets would you like?</p>
                    <div class="d-flex newRaffTktSelBg">
                        <p class="newRaffMinusIconBg mb-0" @click="decreaseCount(raffDetails)"><i class="fa-regular fa-minus newRaffMinusIcon"></i></p>
                        <!-- <p class="newRaffTktCount mb-0">{{ finalTickets }}</p> -->
                        <div>
                        <input type="text" class="newRaffTktCount" v-model="finalTickets" @blur="setDefaultValue" @input="validateInput"/>
                        </div>
                        <p class="newRaffMinusIconBg mb-0" @click="increaseCount(raffDetails)"><i class="fa-regular fa-plus newRaffMinusIcon"></i></p>
                    </div>
                    <p class="newRaffModalDiscTxt">Discount ticket bundles</p>
                    <div class="newTktDiscBundleBg">
                        <div v-for="(discount, index) in sortedDiscounts" :key="index" :class="['newTktDiscountBoxesBg', discount.mostPopular === 'true' ? 'active' : '', selectedDiscount === index ? 'selected' : '' ] " @click="selectDiscount(discount, index)">
                            <p v-if="discount.mostPopular === 'true'" class="newRaffMostPopular mb-0">
                                Most Popular
                            </p>
                            <p class="newRaffTktBundleNum mb-0">{{ discount.entries }}</p>
                            <p class="newRaffTktsTxt mb-0">tickets</p>
                            <p class="newRaffOffPercent mb-0" :class="{ active: discount.mostPopular === 'true' }">
                                {{ discount.discount }}% off
                            </p>
                        </div>
                    </div>
                    <p class="newRaffModalCurrentOdds" v-if="!raffDetails.is_unlimited_entries">Your current odds: <span class="newRaffModalCurrentOddsNum">1 : {{ Math.ceil((raffDetails.total_entries) / finalTickets)}}</span> </p>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn newRaffTktContinueBtn" @click="openQuestions()">
                            <p class="newRaffContTxt mb-0">Continue</p>
                            <p class="newRaffContHr mb-0"></p>
                            <p class="newRaffContTotal mb-0">£{{ discountedTotal }} </p>
                        </button>
                    </div>
                    <p class="newRaffModalEndsTxt mb-0">Raffle ends in {{ timeLeft }}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- question modal -->
    <div class="modalBackdropCustom modal fade" id="questionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl newSelectTicketModal">
            <div class="modal-content newSelectorModalBg">
                <div class="modal-body newRaffTicketSelectorBg">
                    <div class="d-flex justify-content-between">
                        <p @click="goToTicketSelector()" class="ticketSelectBackBtn mb-0"><em class="fa-light fa-arrow-left mobSelectorArrowIcon"></em>Back</p>
                        <h3 class="newMobTktSelHeadTxt newMobQueTxt">Question</h3>
                        <em class="fa-regular fa-xmark newRaffModalCloseIcon" data-bs-dismiss="modal" @click="clear_tickets()"></em>
                    </div>
                    <h3 class="newQueHeadTxt">Question</h3>
                    <div class="row">
                        <div class="col-3 pr-0">
                            <img :src="urlLink + raffDetails.mini_image_url" alt="" class="queRaffImg">
                        </div>
                        <div class="col-6 pl-0 pr-0">
                            <div class="newMobQueRaffLeftMargin">
                                <p class="newQueModalRaffTxt mb-0">{{ raffDetails.title }}</p>
                                <p class="newQueModalTtlEntries">{{ this.finalTickets }} entries selected • £{{ discountedTotal }}</p>
                            </div>
                        </div>
                        <div class="col-2 text-end">
                            <button type="button" class="btn newQueRaffEditBtn mwcSecondayBtnStyles" @click="goToTicketSelector()">edit</button>
                        </div>
                    </div>
                    <hr class="newQueHr">
                    <div>
                        <img :src="urlLink + questionData.question_img" alt="" class="newQueSecImg">
                    </div>
                    <p class="newModalqueTxt">Who is the CEO of Rolex?
                        <span class="tooltip-icon" @mouseover="showTooltip = true">
                            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/Question.svg" alt="" class="questionImg">
                            <span v-if="showTooltip" class="tooltip-text">
                                <div class="toolTipPadding">
                                    <div class="tooltipText">
                                        Complete this step to maximise your chances of success in the raffle!
                                    </div>
                                    <div>
                                        <em class="fa-light fa-xmark newRaffModalCloseIcon" @click="close_tooltip()"></em>
                                    </div>
                                </div>
                            </span>
                        </span>
                    </p>
                    <div class="input-container" :class="{ active: true, 'has-error': false, focused: isFocused }" @click="dropDownClick">
                        <label v-if="showLabel" for="question" class="input-label" :class="{ 'has-error': false }">
                            Who is the CEO of Rolex?
                        </label>
                        <div class="row m-0 align-items-center">
                            <div class="col-12 p-0">
                                <div class="input-field queDropDownInputFields">
                                    {{ selectedAnswer == null ? 'Select answer' : questionData.answers[selectedAnswer].answer }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isDropdownVisible">
                        <ul class="newQueDropdownOptions pr-0">
                            <li class="newAnswerOptionsSec" v-for="(item, index) in questionData.answers" :key="index">
                                <div class="form-check itemAnswerSecForm" @click="answer_select(index)" :class="{ active: selectedAnswer === index }">
                                    <input class="form-check-input itemAnswerRadio" type="radio" name="flexRadioDefault" :id="'flexRadioDefault' + index" :checked="selectedAnswer === index">
                                    <label class="form-check-label" :for="'flexRadioDefault' + index">
                                        {{ item.answer }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <p class="newRaffModalCurrentOdds newQueCurrentOddsMargin" v-if="!raffDetails.is_unlimited_entries">Your current odds: <span class="newRaffModalCurrentOddsNum">1 : {{ Math.ceil((raffDetails.total_entries) / finalTickets)}}</span> </p>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn newRaffQueConfirmBtn disable" v-if="selectedAnswer == null"> Confirm </button>
                        <button type="button" class="btn newRaffQueConfirmBtn mwcButtonStyles" v-else @click="add_to_cart(raffDetails)" :disabled="isLoading"> Confirm </button>
                    </div>
                    <div class="text-left normalErrorMsgTxt" v-if="cartError">
                        {{ cartError }}
                    </div>
                    <p class="newRaffModalEndsTxt mb-0 newQueEndsTxt">Raffle ends in {{ timeLeft }}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- add cart modal -->
    <div class="modalBackdropCustom modal fade" id="addCartModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog addCartModalBg">
            <div class="modal-content addCartNewModal">
                <div class="modal-body newAddCartPadBg">
                    <div class="d-flex justify-content-between mb-4">
                        <p class="newInCartTxt mb-0">In your cart ({{ cart_details.length }})</p>
                        <i class="fa-regular fa-xmark newCartClose" data-bs-dismiss="modal" @click="clear_tickets()"></i>
                    </div>
                    <div v-for="raf in cart_details" :key="raf">
                        <div class="row align-items-center">
                            <div class="col-4">
                                <img src="https://mwc-storage-bucket.s3.ap-south-2.amazonaws.com/dev/images/raffles/5d37a419-4305-43ec-a412-62d525286bca/Collection banner (1).png" alt="" class="newAddCartImg">
                            </div>
                            <div class="col-7 p-0">
                                <div class="newAddCartRaffRightSec">
                                    <p class="newAddCartRaffTxt">{{ raf.title }}</p>

                                    <p class="newAddCartTkts mb-0">{{ raf.finalTickets}} tickets • <span class="newAddCartRaffPrice"> £{{ raf.discountedTotal }}</span> </p>
                                    <p class="newAddCartQueAns mb-0">Answer: {{ raf.QuestAnswer }}</p>
                                </div>
                            </div>
                            <div class="col-1 p-0">
                                <p class="mb-0" @click="deleteRaffle(raf.raffle_id)"> <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/Close.svg" alt="" class="cursor-pointer"> </p>
                            </div>
                        </div>
                        <hr class="newAddCartHrLine">
                    </div>

                    <div class="d-flex justify-content-between newAddCartTotalSecBg">
                        <p class="newAddCartTtlTxt mb-0">TOTAL</p>
                        <p class="newAddCartTtlTxt newAddCartTtlCount mb-0" v-if="showTotalDiscountedAmount">£{{ totalDiscountedAmount() }}</p>
                    </div>
                    <button type="button" class="btn addCartCntBtn mwcButtonStyles" @click="guest_checkout()">Checkout</button>
                    <button type="button" class="btn addCartGoCartBtn mwcSecondayBtnStyles" @click="go_to_cart()">Go to Cart</button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import moment from 'moment-timezone';

import {
    urlLink,
    socialImgUrlLink
} from '@/mixins/s3bucket.js'
import {
    mapGetters
} from 'vuex';
import '@/assets/css/raffleDetails.css';
import '@/assets/css/global.css';
import timer from '@/mixins/timer';
import scrollTop from '@/mixins/scrollTop.js'

import {
    MwcMixin
} from "@/mixins/pathName.js";

export default {
    mixins: [MwcMixin, timer],
    data() {
        return {
            raffDetails: [],
            charityData: {},
            s3Url: socialImgUrlLink,
            urlLink: urlLink,
            finalTickets: 1,
            finalDiscount: 0,
            selectedDiscount: null,
            questionData: [],
            selectedAnswer: null,
            showTooltip: false,
            showTotalDiscountedAmount: false,
            cart_details: [],
            timeLeft: "00d 00h 00m",
            isDropdownVisible: false,
            isFocused: false,
            showLabel: false,
            isDropdownActive: false,
            isDropdownFocused: false,
            cartError : "",
        };
    },

    props: {
        pageData: Object,
    },

    created() {
        this.raffDetails = this.pageData;
        this.fetch_questions()
    },

    computed: {
        sortedDiscounts() {
            const popular = this.raffDetails.discounts.find(
                (item) => item.mostPopular === "true"
            );
            const others = this.raffDetails.discounts.filter(
                (item) => item.mostPopular !== "true"
            );
            const middleIndex = Math.floor(others.length / 2);
            return [
                ...others.slice(0, middleIndex),
                ...(popular ? [popular] : []),
                ...others.slice(middleIndex),
            ];
        },
        ...mapGetters(['getQuestionData']),

        discountedTotal() {
            const totalCost = this.finalTickets * this.raffDetails.entry_cost_gbp;
            const discount = (totalCost * this.finalDiscount) / 100;
            return (totalCost - discount).toFixed(2);
        },

    },

    mounted() {
        this.startCountdownSingle_rafflePage(this.raffDetails);
        this.startTimer();
    },

    beforeDestroy() {
        clearInterval(this.timer); // Clear the timer when the component is destroyed
    },

    methods: {

        openQuestions() {
            $("#ticketSelector").modal("hide");
            $("#questionModal").modal("show");
        },

        close_tooltip() {
            this.showTooltip = false
        },

        startTimer() {
            this.timer = setInterval(() => {
                this.timeLeft = this.formatRaffleExpiry(this.raffDetails.raffle_expire);
            }, 1000);
        },

        formatRaffleExpiry(dateString) {
            const timeZone =
                process.env.VUE_APP_ENV === "dev" || process.env.VUE_APP_ENV === "testing" ?
                "Asia/Kolkata" :
                "Europe/London";
            let dateTime = dateString.split(".000Z")[0];
            let targetDate = moment(dateTime).tz(timeZone);
            let now = moment.tz(timeZone);
            let duration = moment.duration(targetDate.diff(now));
            if (duration.asSeconds() <= 0) {
                return "00d 00h 00m";
            } else {
                const days = String(Math.floor(duration.asDays())).padStart(2, "0");
                const hours = String(duration.hours()).padStart(2, "0");
                const minutes = String(duration.minutes()).padStart(2, "0");
                return `${days}d ${hours}h ${minutes}m`;
            }
        },

        HomeWithLogin() {
            this.$router.push({
                name: 'homeWithLogin',
            });
        },

        decreaseCount(raffle) {
            if (this.finalTickets > 1) {
                this.finalTickets--;
                this.applyMatchingDiscount();
                this.emitSelection();
            }
        },

        increaseCount(raffle) {
            if (this.finalTickets < raffle.total_entries) {
                this.finalTickets++;
                this.applyMatchingDiscount();
                this.emitSelection();
            }
        },

        validateInput(event) {
            let value = event.target.value.replace(/[^0-9]/g, '');
            if (value.length > 5) {
                value = value.slice(0, 5);
            }
            this.finalTickets = value ? parseInt(value, 10) : '';
        },

        setDefaultValue() {
            if (!this.finalTickets || this.finalTickets < 1) {
                this.finalTickets = 1;
            }
        },

        selectDiscount(discount, index) {
            this.finalTickets = parseInt(discount.entries, 10);
            this.finalDiscount = discount.discount;
            this.emitSelection();
            this.selectedDiscount = index;
        },
        applyMatchingDiscount() {
            const sortedDiscounts = [...this.raffDetails.discounts].sort(
                (a, b) => parseInt(a.entries, 10) - parseInt(b.entries, 10)
            );
            let matchingDiscount = null;
            for (const discount of sortedDiscounts) {
                if (this.finalTickets >= parseInt(discount.entries, 10)) {
                    matchingDiscount = discount;
                } else {
                    break;
                }
            }
            if (matchingDiscount) {
                this.finalDiscount = matchingDiscount.discount;
            } else {
                this.finalDiscount = 0;
            }
        },

        goToTicketSelector() {
            $("#ticketSelector").modal("show");
            $("#questionModal").modal("hide");
        },

        emitSelection() {
            this.$emit('update-selection', {
                tickets: this.finalTickets,
                discount: this.finalDiscount,
            });
        },

        clear_tickets() {
            this.finalDiscount = 0;
            this.finalTickets = 1;
            this.selectedAnswer = null;
            this.selectedDiscount = null;
            this.showTooltip = false
        },

        async fetch_questions() {
            try {
                await this.$store.dispatch('fetchQuestions', this.raffDetails.question_id);
                this.questionData = this.getQuestionData.questionsData[0];
            } catch (error) {

            }
        },
        dropDownClick() {
            this.isDropdownVisible = !this.isDropdownVisible
            this.isFocused = true;
            this.showLabel = true;
        },
        answer_select(index) {
            this.selectedAnswer = index;

            this.isDropdownVisible = !this.isDropdownVisible
            this.isFocused = false;
        },

        totalDiscountedAmount() {
            return this.cart_details.reduce((total, raf) => total + parseFloat(raf.discountedTotal || 0), 0).toFixed(2);
        },

        add_to_cart(details) {
            this.isLoading = true;
            if (this.selectedAnswer !== null) {
                this.selectedFinalAnswer = this.questionData.answers[this.selectedAnswer].answer;
                const totalEntries = details.total_entries;
                const ticketsSold = details.tickets_sold;
                this.finalTickets = this.finalTickets !== null ? parseInt(this.finalTickets) : 1;

                this.currentCart = JSON.parse(sessionStorage.getItem('guest_cartDetails')) || [];

                const remainingTickets = totalEntries - ticketsSold;

                const maxTicketsAllowed = details.is_unlimited_entries ? Infinity : remainingTickets;

                const totalTicketsInCartForRaffle = this.currentCart.reduce((total, item) => {
                    if (item.raffle_id === details.raffle_id) {
                        return total + item.finalTickets;
                    }
                    return total;
                }, 0);

                const ticketsToAdd = Math.min(this.finalTickets, maxTicketsAllowed - totalTicketsInCartForRaffle);

                if (ticketsToAdd <= 0) {
                    this.cartError = `You can't add more tickets for this raffle.`;
                    this.isLoading = false;
                    return;
                }

                const existingItemIndex = this.currentCart.findIndex(item => item.raffle_id === details.raffle_id);

                if (existingItemIndex !== -1) {
                    this.currentCart[existingItemIndex].QuestAnswer = this.selectedFinalAnswer;
                    this.currentCart[existingItemIndex].finalTickets += ticketsToAdd;
                    this.currentCart[existingItemIndex].discountedTotal = this.discountedTotal;
                } else {
                    this.currentCart.push({
                        ...details,
                        finalTickets: ticketsToAdd,
                        QuestAnswer: this.selectedFinalAnswer,
                        discountedTotal: this.discountedTotal
                    });
                }

                this.successMess = true;
                this.isLoading = false;
                sessionStorage.setItem('guest_cartDetails', JSON.stringify(this.currentCart));
                sessionStorage.setItem('adding_toCart', true);

                var guest_cart = JSON.parse(sessionStorage.getItem('guest_cartDetails'));
                this.cart_details = guest_cart;
                this.selectedAnswer = null
                $("#questionModal").modal("hide");
                $("#addCartModal").modal("show");
                this.showTotalDiscountedAmount = true
                if (this.isMwcPath()) {
                    dataLayer.push({
                        event: "add_to_cart",
                        ecommerce: {
                            items: [{
                                item_id: details.raffle_id,
                                item_name: details.title,
                                item_category: '',
                                price: details.entry_cost_gbp,
                                quantity: this.finalTickets
                            }]
                        }
                    });
                }

            } else {
                this.isLoading = false;
            }
        },

        open_ticket() {
            this.ticketSection = true;
            this.questionSection = false;
            this.cartError = ""
        },

        go_to_cart() {
            this.$router.push({
                name: 'cartWithoutLogin',
            });
        },

        guest_checkout() {
            // alert("Guest checkout api")
        },

        deleteRaffle(raffleId) {
            const indexToDelete = this.cart_details.findIndex(item => item.raffle_id === raffleId);
            if (indexToDelete !== -1) {
                this.cart_details.splice(indexToDelete, 1);
                sessionStorage.setItem('guest_cartDetails', JSON.stringify(this.cart_details));
                if (this.cart_details.length === 0) {
                    window.location.reload();
                }
            }
        },
    }
}
</script>

<style scoped>
.input-container.focused {
    border-radius: 4px;
    padding: 4px;
    transition: border 0.3s ease;
}
</style>
