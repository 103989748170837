<template>
<div class="raffDtlsBgColor">
    <div v-if="featuredRaffleData" class="featuredRaffleWidth">
        <!-- web featured raffle -->
        <div class="row mwcFeatureRaffleBg">
            <div class="col-6 p-0">
                <h6 class="mwcRaffleDrawtxt">TIME UNTIL DRAW:</h6>
                <div class="d-flex flex-column align-items-start">
                    <div class="mwcDaysTxtBg">
                        <div>
                            <p class="mwcRaffleDrawTime mb-0">
                                {{ countdownDays[featuredRaffleData.raffle_id] }}
                            </p>
                            <h6 class="mwcRaffleTimerTxt mb-0">Days</h6>
                        </div>
                        <div>
                            <p class="mwcRaffleDrawTime mb-0">
                                {{ countdownHours[featuredRaffleData.raffle_id] }}
                            </p>
                            <h6 class="mwcRaffleTimerTxt mb-0">Hours</h6>
                        </div>
                        <div>
                            <p class="mwcRaffleDrawTime mb-0">
                                {{ countdownMinutes[featuredRaffleData.raffle_id] }}
                            </p>
                            <h6 class="mwcRaffleTimerTxt mb-0">Minutes</h6>
                        </div>
                        <div>
                            <p class="mwcRaffleDrawTime mb-0">
                                {{ countdownSeconds[featuredRaffleData.raffle_id] }}
                            </p>
                            <h6 class="mwcRaffleTimerTxt mb-0">Seconds</h6>
                        </div>
                    </div>
                </div>
                <h1 class="mwcraffleName">{{ featuredRaffleData.title }}</h1>
                <div class="d-flex justify-content-center flex-column align-items-start">
                    <div class="d-flex align-items-center">
                        <p class="mwcRaffleTtlTktsTxt mb-0" v-if="!featuredRaffleData.is_unlimited_entries">
                            <em class="fa-light fa-ticket mwcTktIcon"></em>{{ featuredRaffleData.total_entries }} TOTAL TICKETS
                        </p>
                        <hr v-if="!featuredRaffleData.is_unlimited_entries" class="mwcFeatureHr m-0">
                        <p class="newRaffPrice mb-0">
                            £{{ featuredRaffleData.entry_cost_gbp }}
                            per ticket
                        </p>
                    </div>

                    <button type="button" class="btn mwcRaffleSelTktBtn" data-bs-toggle="modal" data-bs-target="#ticketSelector">
                        Select Tickets<em class="fa-solid fa-ticket mwcRaffleTktIcon"></em>
                    </button>
                    <button type="button" class="btn mwcRaffleViewDtlsBtn mwcSecondayBtnStyles" @click="routeToRaffles(featuredRaffleData)">
                        View Details<em class="fa-regular fa-chevron-right mwcRaffleChevronIcon"></em>
                    </button>
                    <p class="newSeeTrmsTxt mb-0">
                        See <span @click="terms_page()" class="newTermsUnderline">terms</span> for
                        free entry
                    </p>
                </div>
            </div>
            <div class="col-6 p-0">
                <div class="mb-raffleImgSec" ref="imageContainer" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
                    <div class="mbThumbArrow" @click="prevImage" :class="{ 'disabled-arrow': selectedIndex === 0 }">
                        <em class="fa-solid fa-arrow-left newMbArrowIcon"></em>
                    </div>
                    <div class="d-flex justify-content-end align-items-end">
                        <img :src="urlLink + selectedImage" alt="" class="featuredRaffImg" />
                    </div>
                    <div class="mbThumbArrowRight" @click="nextImage" :class="{ 'disabled-arrow': selectedIndex === thumbnails.length - 1 }">
                        <em class="fa-solid fa-arrow-right newMbArrowIcon"></em>
                    </div>
                    <div class="thumbnailDotsHome" v-if="featuredRaffleData.thumbnails">
                        <p v-for="(thumbnail, index) in thumbnails" :key="index" class="thumbnail-indicator-home" @click="selectImage(index)" :class="{ active: selectedIndex === index }"></p>
                    </div>
                </div>
            </div>
        </div>

        <!-- mob featured raffle -->
        <div class="mobMwcFeaturedRaffleBg">
            <h6 class="mwcRaffleDrawtxt">TIME UNTIL DRAW:</h6>
            <div>
                <div class="mwcDaysTxtBg">
                    <div>
                        <p class="mwcRaffleDrawTime mb-0">
                            {{ countdownDays[featuredRaffleData.raffle_id] }}
                        </p>
                        <h6 class="mwcRaffleTimerTxt mb-0">Days</h6>
                    </div>
                    <div>
                        <p class="mwcRaffleDrawTime mb-0">
                            {{ countdownHours[featuredRaffleData.raffle_id] }}
                        </p>
                        <h6 class="mwcRaffleTimerTxt mb-0">Hours</h6>
                    </div>
                    <div>
                        <p class="mwcRaffleDrawTime mb-0">
                            {{ countdownMinutes[featuredRaffleData.raffle_id] }}
                        </p>
                        <h6 class="mwcRaffleTimerTxt mb-0">Minutes</h6>
                    </div>
                    <div>
                        <p class="mwcRaffleDrawTime mb-0">
                            {{ countdownSeconds[featuredRaffleData.raffle_id] }}
                        </p>
                        <h6 class="mwcRaffleTimerTxt mb-0">Seconds</h6>
                    </div>
                </div>
            </div>
            <h1 class="mwcraffleName">{{ featuredRaffleData.title }}</h1>

            <p class="mwcRaffleTtlTktsTxt" v-if="!featuredRaffleData.is_unlimited_entries">
                <em class="fa-light fa-ticket mwcTktIcon"></em>{{ featuredRaffleData.total_entries }} TOTAL TICKETS
            </p>

            <div class="mb-raffleImgSec" ref="imageContainer" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
                <div class="mbThumbArrow" @click="prevImage" :class="{ 'disabled-arrow': selectedIndex === 0 }">
                    <em class="fa-solid fa-arrow-left newMbArrowIcon"></em>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <img :src="urlLink + selectedImage" alt="" class="mobMwcImg" />
                </div>
                <div class="mbThumbArrowRight" @click="nextImage" :class="{ 'disabled-arrow': selectedIndex === thumbnails.length - 1 }">
                    <em class="fa-solid fa-arrow-right newMbArrowIcon"></em>
                </div>

                <div class="thumbnailDotsHome" v-if="featuredRaffleData.thumbnails">
                    <p v-for="(thumbnail, index) in thumbnails" :key="index" class="thumbnail-indicator-home" @click="selectImage(index)" :class="{ active: selectedIndex === index }"></p>
                </div>
            </div>

            <div class="d-flex justify-content-center">
                <p class="mwcRaffleCost">
                    £{{ featuredRaffleData.entry_cost_gbp }} PER TICKET
                </p>
            </div>
            <div class="mobFeatureRaffStyles">
                <button type="button" class="btn mwcRaffleSelTktBtn" data-bs-toggle="modal" data-bs-target="#ticketSelector">
                    Select Tickets<em class="fa-solid fa-ticket mwcRaffleTktIcon"></em>
                </button>
                <button type="button" class="btn mwcRaffleViewDtlsBtn mwcSecondayBtnStyles" @click="routeToRaffles(featuredRaffleData)">
                    View Details<em class="fa-regular fa-chevron-right mwcRaffleChevronIcon"></em>
                </button>
            </div>
            <p class="newSeeTrmsTxt mb-0">
                See <span @click="terms_page()" class="newTermsUnderline">terms</span> for
                free entry
            </p>
        </div>
    </div>
    <div v-if="newModalCheck">
        <ticketSelectorWithoutLogin :pageData="raffDetails"></ticketSelectorWithoutLogin>
    </div>

</div>
</template>

<script>
import {
    urlLink,
    socialImgUrlLink
} from "@/mixins/s3bucket.js";

import {
    mapGetters
} from "vuex";

import timer from "@/mixins/timer";
import ticketSelectorWithoutLogin from '@/components/raffleDetailsWithoutLogin/ticketSelectorWithoutLogin.vue';

export default {
    mixins: [timer],

    components: {
        ticketSelectorWithoutLogin
    },

    data() {
        return {
            featuredRaffleData: [],
            raffDetails : {},
            urlLink: urlLink,
            socialImgUrlLink: socialImgUrlLink,
            selectedIndex: 0,
            selectedImage: "",
            thumbnails: [],
            touchStartX: 0,
            touchEndX: 0,
            newModalCheck : false
        };
    },

    created() {
        this.all_raffles();
    },

    computed: {
        ...mapGetters(["getAllRafflesData"]),
    },

    methods: {
        handleClick(event) {
            event.target.blur();
        },

        routeToRaffles(item) {
            this.handleClick(event);
            this.idArray = item;
            this.$router.push({
                name: "raffleDetailsWithoutLogin",
                params: {
                    id: this.idArray.raffle_id.split("Raffle#")[1],
                },
            });
        },

        async all_raffles() {
            try {
                await this.$store.dispatch("fetchAllRaffles");
                this.featuredRaffleData = this.getAllRafflesData.featuredRaffle[0];
                this.newModalCheck = true;
                this.raffDetails =  this.featuredRaffleData
                this.thumbnails = this.featuredRaffleData.thumbnails;
                this.selectedImage =
                    this.thumbnails[0] ?.newSignInKey || this.featuredRaffleData.main_image_url;
                this.selectedIndex = 0;

                this.startCountdownSingle_rafflePage(this.featuredRaffleData);
            } catch (error) {
                console.error("Error fetching all raffles:", error);
            }
        },

        selectImage(index) {
            this.selectedIndex = index;
            this.selectedImage = this.thumbnails[index].newSignInKey;

            const imageContainer = this.$refs.imageContainer;
            const thumbnailWidth = 50;
            const scrollLeft = thumbnailWidth * index;
            imageContainer.scrollLeft = scrollLeft;
        },
        nextImage() {
            if (this.selectedIndex === this.thumbnails.length - 1) return;
            this.selectImage(this.selectedIndex + 1);
        },
        prevImage() {
            if (this.selectedIndex === 0) return;
            this.selectImage(this.selectedIndex - 1);
        },
        onTouchStart(event) {
            this.touchStartX = event.touches[0].clientX;
        },
        onTouchMove(event) {
            this.touchEndX = event.touches[0].clientX;
        },
        terms_page() {
            localStorage.setItem("scrollToFreeEntry", true);
            setTimeout(() => {
                this.$router.push("/termsAndConditions");
            }, 500);
        },
    },
};
</script>
