<template>
    <div class="mwcWinnersCarouselSecBg">
        <h1 class="mwcPrevWinTxt mob-winHeadHide">How many winners we’ve made so far or something...</h1>
        <h1 class="mwcPrevWinTxt web-winHeadHide">How many winners we’ve made so far or something...</h1>
        <p class="mwcDescTxtNew">With every ticket, you bring yourself closer to experiencing the thrill of a win. Could the next big moment be yours?</p>

        <!-- if it is single winner -->
        <div v-if="winnersMonthwiseDetails.length === 1" class="d-flex justify-content-center">
            <div v-for="winner in winnersMonthwiseDetails" :key="winner">
                <div class="winnerImgSec">
                    <img :src="urlLink + winner.img_url" alt="" class="winnerSecImg">
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn winnerBtn"
                           >WINNER</button>
                    </div>
                    <h1 class="winnerNameTxt">{{ winner.title }}</h1>
                    <p class="winnerWonByTxt">{{ winner.location !== "null" ? `Won by
                        ${capitalizeFirstLetter(winner.name.split(' ')[0])} in ${winner.location}` : `Won by
                        ${capitalizeFirstLetter(winner.name.split(' ')[0])}` }}</p>
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn winnerViewDtlsBtn mwcSecondayBtnStyles"
                            @click="handleWinnerButtonClick(winner.sort_id)">VIEW DETAILS<i class="fa-light fa-chevron-right winnerViewChevron"></i></button>
                        </div>
                         

                </div>
            </div>
        </div>
        <!-- if it is 2 to 4 -->
        <div v-if="winnersMonthwiseDetails.length > 1 && winnersMonthwiseDetails.length <= 4">
            <div class="mb-winnersCarouselHide">
                <div v-for="winner in winnersMonthwiseDetails" :key="winner">
                    <div class="winnerImgSec">
                        <img :src="urlLink + winner.img_url" alt="" class="winnerSecImg">
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn winnerBtn"
                                >WINNER</button>
                        </div>
                        <h1 class="winnerNameTxt">{{ winner.title }}</h1>
                        <p class="winnerWonByTxt">{{ winner.location !== "null" ? `Won by
                            ${capitalizeFirstLetter(winner.name.split(' ')[0])} in ${winner.location}` : `Won by
                            ${capitalizeFirstLetter(winner.name.split(' ')[0])}` }}</p>
                                                    <div class="d-flex justify-content-center">
                            <button type="button" class="btn winnerViewDtlsBtn mwcSecondayBtnStyles"
                            @click="handleWinnerButtonClick(winner.sort_id)">VIEW DETAILS<i class="fa-light fa-chevron-right winnerViewChevron"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="web-winnersCarouselHide">
                <div class="sec-bg">
                    <div class="center">
                        <div v-for="winner in winnersMonthwiseDetails" :key="winner" class="slick-slide">
                            <div class="winnerImgSec">
                                <img :src="urlLink + winner.img_url" alt="" class="winnerSecImg">
                                <div class="d-flex justify-content-center">
                                    <button type="button" class="btn winnerBtn"
                                        >WINNER</button>
                                </div>
                                <h1 class="winnerNameTxt">{{ winner.title }}</h1>
                                <p class="winnerWonByTxt">{{ winner.location !== "null" ? `Won by
                                    ${capitalizeFirstLetter(winner.name.split(' ')[0])} in ${winner.location}` : `Won by
                                    ${capitalizeFirstLetter(winner.name.split(' ')[0])}` }}</p>
                                   <div class="d-flex justify-content-center">
                            <button type="button" class="btn winnerViewDtlsBtn mwcSecondayBtnStyles"
                            @click="handleWinnerButtonClick(winner.sort_id)">VIEW DETAILS<i class="fa-light fa-chevron-right winnerViewChevron"></i></button>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- carousel section starts -->
        <div class="sec-bg">
            <div class="center" v-if="winnersMonthwiseDetails.length > 4">
                <div v-for="winner in winnersMonthwiseDetails" :key="winner" class="slick-slide">
                    <div class="winnerImgSec">
                        <img :src="urlLink + winner.img_url" alt="" class="winnerSecImg">
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn winnerBtn"
                                >WINNER</button>
                        </div>
                        <h1 class="winnerNameTxt">{{ winner.title }}</h1>
                        <p class="winnerWonByTxt">{{ winner.location !== "null" ? `Won by
                            ${capitalizeFirstLetter(winner.name.split(' ')[0])} in ${winner.location}` : `Won by
                            ${capitalizeFirstLetter(winner.name.split(' ')[0])}` }}</p>
                               <div class="d-flex justify-content-center">
                            <button type="button" class="btn winnerViewDtlsBtn mwcSecondayBtnStyles"
                            @click="handleWinnerButtonClick(winner.sort_id)">VIEW DETAILS<i class="fa-light fa-chevron-right winnerViewChevron"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class='d-flex justify-content-center'>
            <button type="button" class="btn viewPastWinnersBtn mwcSecondayBtnStyles" @click="winners()">VIEW ALL WINNERS</button>
        </div>
    </div>
</template>

<script>
import {
    urlLink,
    socialImgUrlLink
} from '@/mixins/s3bucket.js'

import {
    mapGetters
} from 'vuex';

import $ from "jquery";
import "slick-carousel/slick/slick.min.js";
import "slick-carousel/slick/slick.css";
import "@/assets/slick/slick-theme.css"
export default {
    data: () => ({
        userSession: "",
        winnersMonthwiseDetails: [],
        urlLink: urlLink,
        socialImgUrlLink: socialImgUrlLink
    }),

    created() {
        this.userSession = localStorage.getItem("user_session");
        this.winners_Carousel();
    },

    computed: {
        ...mapGetters(['getwinnersCarouselData'])
    },

    watch: {
        winnersMonthwiseDetails: {
            handler() {
                this.$nextTick(() => {
                    this.initializeSlickCarousel();
                });
            },
            immediate: true,
        },
    },

    methods: {

        initializeSlickCarousel() {
            $('.center').slick({
                variableWidth: true,
                arrows: true,
                centerPadding: '50%',
                slidesToShow: 4,
                swipeToSlide: true,
                centerMode: true,
                prevArrow: "<div class='winnersPageLeftArrowBg'><button class='slick-prev slick-arrow' aria-label='Previous' type='button' >Previous</button></div>",
                nextArrow: "<div class='winnersPageRightArrowBg'><button class='slick-next slick-arrow' aria-label='Next' type='button' >Next</button></div>",
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        arrows: true,
                        centerMode: true,
                        centerPadding: '100px',
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        dots: true,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '100px',
                        slidesToShow: 1
                    }
                }
                ]
            });
            const slickSlides = document.querySelectorAll(".slick-cloned");
            slickSlides.forEach(slide => {
                slide.style = "";
            });
        },

        async winners_Carousel() {
            try {
                await this.$store.dispatch('fetchWinnersCarousel');
                this.winnersMonthwiseDetails = this.getwinnersCarouselData.winners;
            } catch (error) {
                console.error("Error fetching winners CarouselData:", error);
            }
        },

        winners() {
            if (!this.userSession) {
                this.$router.push({
                    name: 'winnersWithoutLogin',
                });
            } else {
                this.$router.push({
                    name: 'winnersWithLogin',
                });
            }
        },

        capitalizeFirstLetter(name) {
            return name.charAt(0).toUpperCase() + name.slice(1);
        },

        handleWinnerButtonClick(sort_id) {
            if (!this.userSession) {
                this.$router.push({
                    name: 'drawCertificate',
                    params: {
                        sort_id: sort_id.split('Winner#')[1],
                    }
                });
            }
            else {
                this.$router.push({
                    name: 'drawCertificateWithLogin',
                    params: {
                        sort_id: sort_id.split('Winner#')[1],
                    }
                });
            }

        }

    },
};
</script>

<style>
.slick-dots{
    display:block !important;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 5px;
    height: 20px;
    margin: 0 8px;
    padding: 0;
    cursor: pointer;
}

.individual-indicators>.slide {
    display: inline-block;
}

.winnersPageLeftArrowBg {
    width: 311px;
    height: 445px;
    position: absolute;
    z-index: 1020;
    pointer-events: none;
}

.dark .winnersPageLeftArrowBg {
    background: linear-gradient(269deg, rgba(0, 6, 22, 0.00) 0%, #000616 100%);
}

.winnersPageRightArrowBg {
    width: 311px;
    height: 445px;
    position: absolute;
    z-index: 1020;
    right: 0;
    top: 0;
    pointer-events: none;
}

.slick-slide {
    width: 332px !important;
    box-sizing: border-box;
    margin: 0px 12px;
}

.slick-next {
    right: 56px !important;
    width: 48px;
    height: 48px;
    background: #fff !important;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
    pointer-events: fill;
}

.dark .slick-next {
    background: #0e1123 !important;
}

.slick-next:before {
    content: '\f054';
    color: rgb(0, 0, 0, 0.8) !important;
}

.dark .slick-next:before {
    color: #fff !important;
}

.slick-prev {
    left: 56px !important;
    color: red !important;
    z-index: 9999 !important;
    width: 48px;
    height: 48px;
    background: #fff !important;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
    pointer-events: fill;
}

.dark .slick-prev {
    background: #0e1123 !important;
}

.slick-prev:before {
    content: '\f053';
    color: rgb(0, 0, 0, 0.8) !important;
}

.dark .slick-prev:before {
    color: #fff !important;
}

.slick-prev:hover {
    color: #000 !important;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'Font Awesome 6 Pro', sans-serif !important;
    font-size: 16px;
    line-height: 1;
    opacity: .75;
    color: #000 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* .slick-active>div.card-dsgn {
    width: 237px;
} */

@media (max-width:767px) {

    /* .slick-active>div.card-dsgn {
        width: 216px;
        min-height: 280px;
        margin: auto;
    } */
    .slick-active>div>div>img.user-img {
        width: 140px;
        height: 140px;
        margin: 9px auto;
    }

    .slick-active>div>div>div.user-nameTxt {
        font-size: 16px;
        margin: 8px 0px 5px;
    }

    .slick-active>div>div>div.itemWon-txt {
        font-size: 12px;
        margin-bottom: 13px;
    }

    .slick-active>div>div>div.draw-btn {
        font-size: 12px;
        width: 140px;
        height: 32px;
    }

    .slick-active>div>div>img.drawImgLight {
        width: 9px !important;
        height: 12px !important;
        margin-right: 6px;
    }

    .slick-active>div>div>img.drawImgDark {
        width: 9px !important;
        height: 12px !important;
        margin-right: 6px;
    }

    .slick-slide {
        width: 234px !important;
        box-sizing: border-box;
        margin: 0px 8.5px;
    }

    .winnersPageLeftArrowBg {
        display: none !important;
    }

    .winnersPageRightArrowBg {
        display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 959px) {
.slick-slide{
    width: 234px !important;
}
}
@media (min-width: 960px) and (max-width: 1284px){
    .slick-slide{
    width: 234px !important;
    margin:0px 8.5px;
}
}
</style>
