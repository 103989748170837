<template>
<div class="mwcDeliverSupportSecBg">
    <div class="mwcDeliverySecBg">
    <div class="d-flex justify-content-center">
      <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/fast+delivery.svg" alt="" class="rapidDelImg">  
    </div>
        <p class="rapidDeliveyTxt">Rapid Delivery</p>
        <p class="rapidDeliveryDescTxt mb-0">We aim to get all watches to our winners within 72 hours</p>
    </div>
    <div class="mwcDeliverySecBg">
    <div class="d-flex justify-content-center">
    <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/money.svg" alt="" class="rapidDelImg">
    </div>
        <p class="rapidDeliveyTxt">Best Odds</p>
        <p class="rapidDeliveryDescTxt mb-0">We ensure we have the best odds for winning your dream watch</p>
    </div>
    <div class="mwcDeliverySecBg">
    <div class="d-flex justify-content-center">
    <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/lock.svg" alt="" class="lockIcon">
    </div>
        <p class="rapidDeliveyTxt">Secure Payments</p>
        <p class="rapidDeliveryDescTxt mb-0">We accept a number of different secure payment methods </p>
    </div>
    <div class="mwcDeliverySecBg">
    <div class="d-flex justify-content-center">
    <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/call.svg" alt="" class="phoneIcon">
    </div>
        <p class="rapidDeliveyTxt">Quality Guarantee</p>
        <p class="rapidDeliveryDescTxt mb-0">Our friendly team are available via live chat / phone to help!</p>
    </div>
</div>
</template>
